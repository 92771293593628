import { api } from "./config";
const TagsService = {
  getTags() {
    return api.get("/tags");
  },
  deleteTags(id) {
    return api.token().delete(`/tags/${id}`);
  },
  postTag(data) {
    return api.token().post(`/tags`, data);
  },
  updateTag(data) {
    return api.token().patch(`/tags/${data.id}`, data);
  },
};
export default TagsService;
