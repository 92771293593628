import Sider from "antd/es/layout/Sider";
import React from "react";

export default function SideNav({ children, collapsed }) {
  return (
    <Sider
      className="border-l border-l-white"
      trigger={null}
      collapsible
      width={collapsed ? 0 : "40vw"}
      collapsed={collapsed}
      style={{
        top: 0,
        position: "fixed",
        right: collapsed ? -100 : 0,
        height: "100vh",
        zIndex: 100,
        background: "#fafafa",
      }}
    >
      {children}
    </Sider>
  );
}
