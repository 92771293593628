import { createSlice } from "@reduxjs/toolkit";
import { NotificationManager } from "react-notifications";

// Initial state
const initialState = {
  donhangs: [],
  loading: false,
  error: null,
};

// Slice
const donhangSlice = createSlice({
  name: "donhang",
  initialState,
  reducers: {
    setDonhangs: (state, action) => {
      state.donhangs = action.payload;
      state.loading = false;
      state.error = null;
    },
    postData: (state, action) => {
      NotificationManager.success("Cảm ơn bạn đã đặt hàng");
      state.donhangs = [action.payload, ...state.donhangs];
      state.loading = false;
      state.error = null;
    },
    updateDonhang: (state, action) => {
      const index = state.donhangs.findIndex((x) => x.id === action.payload.id);
      state.danhmucs[index] = action.payload;
      state.loading = false;
      state.error = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

// Export actions and reducer
export const { setDonhangs, setLoading, setError, postData, updateDonhang } =
  donhangSlice.actions;

export const selectDonhangs = (state) => state.donhang.donhangs;
export const selectLoading = (state) => state.donhang.loading;
export const selectError = (state) => state.donhang.error;

export default donhangSlice.reducer;
