import { Modal } from "antd";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectDanhmuc } from "../redux/features/danhmuc/danhmucSlice";
import { getImages, postImage } from "../redux/features/image/imageAction";
import { selectImage } from "../redux/features/image/imageSlice";

export default function ModalUpload({
  isOpen,
  handleOk,
  handleCancel,
  handleImageClick,
  handleReturn,
  listImage = [],
}) {
  const [danhmucSelected, setDanhmucSelect] = useState(null);
  const danhmucSelector = useSelector(selectDanhmuc);
  const imageSelector = useSelector(selectImage);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      dispatch(postImage(file, danhmucSelected.slug));
    }
  };

  const handleUploadClick = () => {
    // Kích hoạt sự kiện click trên input để chọn tệp
    fileInputRef.current.click();
  };
  return (
    <Modal
      width={"60vw"}
      title="Folder Upload"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <div
        className={`mb-4 cursor-pointer ${
          danhmucSelected ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
      >
        <h2
          className="text-xl font-medium "
          onClick={() => {
            handleReturn(); // Gọi hàm handleReturn từ props
            setDanhmucSelect(null); // Đặt state bằng null
          }}
        >
          Quay lại
        </h2>
      </div>
      {danhmucSelected ? (
        <div className="grid grid-cols-4 gap-4 mt-4">
          <div
            className={`relative bg-gray-200 cursor-pointer group h-60 p-4 rounded-2xl flex items-center justify-center `}
            onClick={handleUploadClick}
          >
            <input
              type="file"
              accept="image/*"
              className="hidden"
              ref={fileInputRef}
              onChange={handleFileInputChange}
            />
            <span className="material-symbols-outlined text-5xl">upload</span>
          </div>
          {imageSelector?.map((e, i) => (
            <div
              key={i}
              className={`relative bg-gray-200 cursor-pointer group h-60 p-4 rounded-2xl flex items-center justify-center ${
                listImage.includes(e) ? "bg-gray-400" : ""
              }`}
              onClick={() => handleImageClick(e)}
            >
              <img className="h-52" src={e} alt="" />
            </div>
          ))}
        </div>
      ) : (
        <div className="grid grid-cols-4 gap-4">
          {danhmucSelector.map((e, i) => (
            <div
              key={i}
              className="relative bg-gray-200 cursor-pointer group h-60 rounded-2xl flex items-center justify-center flex-col space-y-2 text-2xl"
              onClick={() => {
                setDanhmucSelect(e);
                dispatch(getImages(e.slug));
              }}
            >
              <span className="material-symbols-outlined text-5xl font-bold">
                folder
              </span>
              <span>{e.title}</span>
            </div>
          ))}
        </div>
      )}
    </Modal>
  );
}
