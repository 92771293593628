import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { postTag } from "../../redux/features/tags/tagAction";
// import { deleteTag } from "@/redux/features/tag/tagAction";
// import { postTag } from "@/redux/features/tag/tagAction";

const initialValues = {
  title: "",
};
const validationSchema = Yup.object().shape({
  title: Yup.string().required("*Tag không được để trống"),
});

export default function TagDetail({ tag }) {
  const formikRef = useRef();
  const [formTag, setFormTag] = useState(initialValues);

  const dispatch = useDispatch();
  useEffect(() => {
    if (tag) {
      setFormTag(tag);
    } else {
      setFormTag(initialValues);
    }
  }, [tag]);

  const handleSave = () => {
    if (formikRef.current) {
      formikRef.current.submitForm();
    }
  };
  const handleSubmit = (values, { setSubmitting }) => {
    dispatch(postTag(values));
    // Xử lý khi form được submit
  };

  // Hàm xử lý khi click vào một hình ảnh

  //   const handleDeleteTag = () => {
  //     dispatch(deleteTag(tag.id));
  //   };

  return (
    <div className="text-black 2xl:mt-16  p-5">
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-between w-full">
          {tag ? (
            <div className="flex space-x-4">
              <div className="space-y-2">
                <h2 className="text-2xl font-bold text-black">{tag.title}</h2>
                <span className="font-bold inline-block text-base">
                  Tag Detail
                </span>
              </div>
            </div>
          ) : (
            <h2 className="text-2xl font-bold text-black">Tag Detail</h2>
          )}

          <div className="flex items-center space-x-4">
            {formTag.id && (
              <div
                className="flex items-center  rounded-2xl bg-black text-white hover:text-gray-400 cursor-pointer justify-center text-lg font-bold py-1 px-4 space-x-2"
                // onClick={handleDeleteTag}
              >
                <span className="material-symbols-outlined">delete</span>
                <span>Remove</span>
              </div>
            )}

            <div
              className="flex items-center  rounded-2xl bg-black text-white hover:text-gray-400 cursor-pointer justify-center text-lg font-bold py-1 px-4 space-x-2"
              onClick={handleSave}
            >
              <span className="material-symbols-outlined">save</span>
              <span>Save</span>
            </div>
          </div>
        </div>
      </div>

      <div style={{ padding: 20 }}>
        <Formik
          innerRef={formikRef}
          initialValues={formTag}
          validationSchema={validationSchema}
          enableReinitialize={true} // Thêm dòng này
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values }) => {
            return (
              <Form className="space-y-6">
                <div className="mt-6 space-y-6 text-lg">
                  <div className="flex flex-col space-y-2 ">
                    <label htmlFor="title">
                      Tên
                      <span className="underline decoration-dotted inline-block ml-1">
                        *
                      </span>
                    </label>
                    <Field
                      type="text"
                      id="title"
                      name="title"
                      className="bg-transparent  border border-gray-400 p-3"
                    />
                    <ErrorMessage
                      name="title"
                      className="text-red-500"
                      component="div"
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}
