import DanhmucService from "@/service/danhmuc";
import {
  setError,
  setLoading,
  setDanhmucs,
  deleteDanhmucSlice,
  postData,
  updateDanhmuc,
} from "./danhmucSlice";

export const getDanhmucs = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const result = await DanhmucService.getDanhmucs();
    await dispatch(setDanhmucs(result));
  } catch (error) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const postDanhmuc = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const result = await DanhmucService.postDanhmuc(data);
    dispatch(postData(result));
  } catch (error) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};
export const updateData = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const result = await DanhmucService.updateDanhmuc(data);
    dispatch(updateDanhmuc(result));
  } catch (error) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};


export const deleteDanhmuc = (id) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    DanhmucService.deleteDanhmuc(id);
    
    dispatch(deleteDanhmucSlice(id));
  } catch (error) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};
