import React from "react";
import Categories from "./categories";
import Email from "./email";
import Gallery from "./gallery";
import Product from "./product";
import Slider from "./slider";

export default function Homepage() {
  return (
    <>
      <Slider />
      <Categories />
      <Product />
      <Gallery />
      <Email />
    </>
  );
}
