import DonhangService from "@/service/donhang";
import { handleClearCart } from "../cart/cartAction";
import {
  setError,
  setLoading,
  setDonhangs,
  updateDonhang,
  postData,
} from "./donhangSlice";

export const getDonhangs = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const result = await DonhangService.getDonhangs();
    await dispatch(setDonhangs(result));
  } catch (error) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const postDonhang = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const result = await DonhangService.postDonhang(data);
    await dispatch(postData(result));
  } catch (error) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateDonhangAction = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const result = await DonhangService.updateDonhang(data);
    dispatch(handleClearCart());
    dispatch(updateDonhang(result));
  } catch (error) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};
