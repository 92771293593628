import { endpoint } from "../service/config";

export class Api {
  endpoint = "";
  useToken = false;
  constructor(endpoint) {
    this.endpoint = endpoint;
    this.patch = this.patch.bind(this);
  }

  token() {
    this.useToken = true;
    return this;
  }

  json(res) {
    return res.json();
  }

  setUpHeader() {
    let header = {
      "Content-type": "application/json",
    };
    if (this.useToken) {
      let token = localStorage.getItem("accessToken");
      if (token) {
        header.Authorization = `Bearer ${token}`;
      }
    }
    this.useToken = false;
    return header;
  }
  async refreshToken() {
    let refreshToken = JSON.parse(localStorage.getItem("login"))?.token
      ?.refreshToken;
    if (refreshToken) {
      let res = await fetch(`${endpoint}/elearning/v4/refresh-token`, {
        method: "POST",
        body: JSON.stringify({
          refreshToken: refreshToken,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => res.json());
      if (res?.data?.accessToken) {
        localStorage.setItem("token", JSON.stringify(res.data));
      }
    }
    return true;
  }
  async request(url, options) {
    let response = await fetch(url, options);
    if (response.status === 200) {
      return response;
    }
    // if (response.status === 403) {
    //   await this.refreshToken();
    //   let token = JSON.parse(localStorage.getItem("token"))?.accessToken;
    //   if (token) {
    //     options.headers.Authorization = `Bearer ${token}`;
    //   }
    //   return fetch(url, options);
    // }
    return response;
  }
  get(url) {
    let headers = this.setUpHeader();

    return this.request(this.endpoint + url, {
      headers,
      method: "GET",
    }).then(this.json);
  }
  post(url, data) {
    let headers = this.setUpHeader();
    let body = JSON.stringify(data);
    return this.request(this.endpoint + url, {
      method: "POST",
      headers,
      body,
    }).then(this.json);
  }
  patch(url, data) {
    let headers = this.setUpHeader();
    let body = JSON.stringify(data);
    return this.request(this.endpoint + url, {
      method: "PATCH",
      headers,
      body,
    }).then(this.json);
  }
  delete(url, data) {
    let headers = this.setUpHeader();

    let body = JSON.stringify(data);
    return this.request(this.endpoint + url, {
      method: "DELETE",
      headers,
      body,
    }).then(this.json);
  }
}
