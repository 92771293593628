import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "@/componnents/breadcrumb";
import useCurrency from "@/hook/useCurrency";
import {
  selectCartAmount,
  selectCartList,
} from "@/redux/features/cart/cartSlice";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { selectUser } from "@/redux/features/user/userSlice";
import { postDonhang } from "@/redux/features/donhang/donhangAction";
import { handleClearCart } from "../../redux/features/cart/cartAction";
export default function Checkout() {
  const formatCurrency = useCurrency();
  const cartList = useSelector(selectCartList);
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const amount = useSelector(selectCartAmount);
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("*Email không hợp lệ")
      .required("*Email không được để trống"),
    name: Yup.string().required("*Họ và tên không được để trống"),
    address: Yup.string().required("*Địa chỉ không được để trống"),
    phone: Yup.string()
      .matches(/(84|0[3|5|7|8|9])+([0-9]{8})\b/i, "Số điện thoại không hợp lệ")
      .required("Số điện thoại không được để trống"),
  });
  const data = [
    {
      name: "Thanh Toán",
      link: "thanh-toán",
    },
  ];
  const initialValues = {
    email: user?.email || "",
    phone: "",
    address: "",
    name: user?.displayName || "",
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const data = {
      form: values,
      carts: cartList,
    };
    dispatch(postDonhang(data));
    dispatch(handleClearCart());
    navigate("/");
    // Xử lý khi form được submit
  };
  return (
    <>
      <div className="space-y-10">
        <Breadcrumb data={data} />
        {!user && (
          <div className="flex items-center space-x-2">
            <h2>Bạn đã có tài khoản? </h2>
            <Link to="/dang-nhap" className="text-gray-400 underline">
              đăng nhập
            </Link>
          </div>
        )}
        {cartList.length > 0 ? (
          <div className="flex lg:flex-row flex-col-reverse lg:space-x-12 space-x-0 ">
            <div className="lg:w-1/2 w-full space-y-6 lg:mt-0 mt-6">
              <h2 className="font-bold uppercase lg:text-2xl text-xl">
                THÔNG TIN THANH TOÁN
              </h2>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <Form className="space-y-6">
                    <div className="flex flex-col space-y-2">
                      <label htmlFor="name" className="text-gray-400">
                        Họ và tên
                        <span className="underline decoration-dotted inline-block ml-1">
                          *
                        </span>
                      </label>
                      <Field
                        type="name"
                        id="name"
                        name="name"
                        className="bg-transparent  border border-gray- p-3 text-gray-400"
                      />
                      <ErrorMessage
                        name="name"
                        className="text-red-500"
                        component="div"
                      />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label htmlFor="phone">Số điện thoại</label>
                      <Field
                        type="phone"
                        id="phone"
                        name="phone"
                        className="bg-transparent  border border-gray- p-3 text-gray-400"
                      />
                      <ErrorMessage
                        name="phone"
                        className="text-red-500"
                        component="div"
                      />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label htmlFor="email">Email</label>
                      <Field
                        type="email"
                        id="email"
                        name="email"
                        className="bg-transparent  border border-gray- p-3 text-gray-400"
                      />
                      <ErrorMessage
                        name="email"
                        className="text-red-500"
                        component="div"
                      />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label htmlFor="address" className="text-gray-400">
                        Địa chỉ
                        <span className="underline decoration-dotted inline-block ml-1">
                          *
                        </span>
                      </label>
                      <Field
                        type="address"
                        id="address"
                        name="address"
                        className="bg-transparent  border border-gray- p-3 text-gray-400"
                      />
                      <ErrorMessage
                        name="address"
                        className="text-red-500"
                        component="div"
                      />
                    </div>

                    <button
                      className="button-submit text-nowrap"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Đặt hàng
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="lg:w-1/2 w-full space-y-6">
              <h2 className="font-bold uppercase lg:text-2xl text-xl">
                ĐƠN HÀNG CỦA BẠN
              </h2>

              <table className="w-full table-auto overflow-auto">
                <thead>
                  <tr className="border-b border-gray-300 text-left">
                    <th className="px-4 py-3 uppercase lg:text-lg">Sản phẩm</th>

                    <th className="px-4 py-3 uppercase lg:text-lg">Tạm tính</th>
                  </tr>
                </thead>
                <tbody>
                  {cartList.map((e, i) => (
                    <tr className="border-b border-gray-300 text-left" key={i}>
                      <td className="px-4 py-3 font-bold uppercaese text-gray-400">
                        {e.name} x {e.cartNum}
                      </td>
                      <td className="px-4 py-3 text-gray-400">
                        {formatCurrency(e.price)}
                      </td>
                    </tr>
                  ))}

                  <tr className="border-b border-gray-300 text-left">
                    <td className="px-4 py-3 font-bold uppercaes lg:text-lg">
                      Tạm tính
                    </td>
                    <td className="px-4 py-3 text-gray-400">
                      {formatCurrency(amount)}
                    </td>
                  </tr>
                  <tr className="border-b border-gray-300 text-left">
                    <td className="px-4 py-3 font-bold uppercaes lg:text-lg">
                      Tổng
                    </td>
                    <td className="px-4 py-3 text-white">
                      {formatCurrency(amount)}
                    </td>
                  </tr>
                </tbody>
              </table>
              <p className="text-gray-400">
                Dữ liệu cá nhân của bạn sẽ được sử dụng để xử lý đơn đặt hàng,
                hỗ trợ trải nghiệm của bạn trên trang web này và cho các mục
                đích khác được mô tả trong chính sách bảo mật của chúng tôi.
              </p>
            </div>
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center space-y-8">
            <h2 className="text-3xl font-bold uppercase">
              giỏ hàng hiện tại của bạn đang trống
            </h2>
            <Link
              to="/gaoranger"
              className="button-submit text-nowrap"
              type="submit"
            >
              Trở lại danh mục
            </Link>
          </div>
        )}
      </div>
    </>
  );
}
