import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { selectUser } from "../redux/features/user/userSlice";

export default function PrivateRoute({ children }) {
  const user = useSelector(selectUser);

  if (!user || user.role !== "admin") {
    return <Navigate to="/" />;
  }

  return children;
}
