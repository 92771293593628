import { useCallback } from "react";

const useCurrency = () => {
  const formatCurrency = useCallback((value) => {
    return new Intl.NumberFormat("vi-VN", {
      style: "currency",
      currency: "VND",
    })
      .format(value)
      .replace(/,/g, ".")
      .replace(/\./g, ",");
  }, []);

  return formatCurrency;
};

export default useCurrency;
