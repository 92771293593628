import { api } from "./config";
const DonhangService = {
  getDonhangs() {
    return api.token().get("/donhang");
  },
  postDonhang(data) {
    return api.post(`/donhang`, data);
  },
  updateDonhang(data) {
    console.log(data);
    return api.token().patch(`/donhang/${data.id}`, data);
  },
};
export default DonhangService;
