import React, { useEffect, useRef } from "react";
import Breadcrumb from "../../componnents/breadcrumb";

export default function About() {
  const imgRef = useRef(null);
  const wrapRef = useRef(null);
  const data = [
    {
      name: "Về chúng tôi",
      link: "ve-chung-toi",
    },
  ];
  useEffect(() => {
    const wrapElement = wrapRef.current;
    const imgElement = imgRef.current;
    const handleScroll = () => {
      const { bottom } = wrapElement.getBoundingClientRect();
      const scrollTop = window.scrollY;
      imgElement.classList.add("about-sticky");
      imgElement.classList.remove("about-absolute");
      if (scrollTop >= bottom + 500) {
        imgElement.classList.remove("about-sticky");
        imgElement.classList.add("about-absolute");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <Breadcrumb data={data} />
      <div className="flex lg:flex-row flex-col  lg:px-20 lg:space-x-10 lg:space-y-0 space-y-8  w-full">
        <div className="lg:w-1/3 ">
          <div className="h-full relative" ref={wrapRef}>
            <img
              className="lg:w-[370px] 2xl:w-[432px]"
              ref={imgRef}
              src="https://scontent.fhan14-5.fna.fbcdn.net/v/t39.30808-1/178900657_1001745090599204_8019842345720844535_n.jpg?stp=dst-jpg_s480x480&_nc_cat=106&ccb=1-7&_nc_sid=0ecb9b&_nc_ohc=twAX9VUicVwQ7kNvgGWMHKn&_nc_ht=scontent.fhan14-5.fna&oh=00_AYD-XDGpKVlKMhOW1zKRZXJlb7PjKV_GuxcooM0DikgAdA&oe=669160DF"
              alt=""
            />
          </div>
        </div>
        <div className="lg:w-2/3 space-y-20">
          <div className="flex space-x-10 items-center justify-between relative md:pl-10 pl-2">
            <span className="uppercase text-lg absolute -left-12 top-16 translate-rotate">
              About me
            </span>
            <div className="space-y-4">
              <h2 className="md:text-3xl text-xl font-bold uppercase">
                Gian thương số 1 miền nam
              </h2>
              <p className="text-justify">
                Chuyên lừa đảo khách hàng bằng cách cung cấp thông tin sai lệch
                về sản phẩm hoặc không đúng với mô tả. Khách hàng thường gặp
                phải các vấn đề như chất lượng sản phẩm kém, khó khăn trong việc
                liên hệ với dịch vụ chăm sóc khách hàng và không được hoàn trả
                tiền khi có khiếu nại.{" "}
              </p>
            </div>
          </div>
          <div className="w-full flex space-x-10 md:pl-20 pl-2">
            <div className="w-1/2 space-y-3">
              <h2 className="font-bold uppercase text-xl">Giải thưởng</h2>
              <span className="text-gray-400 inline-block">
                Oslo Design Award
              </span>
              <span className="text-gray-400 inline-block">Chagall Design</span>
              <span className="text-gray-400 inline-block">
                The Design Award 2018
              </span>
              <span className="text-gray-400 inline-block">
                DKL / Liene Chihent
              </span>
              <span className="text-gray-400 inline-block">
                Nature / Identity
              </span>
              <span className="text-gray-400 inline-block">
                Awwwwards Design
              </span>
            </div>
            <div className="w-1/2 space-y-3">
              <h2 className="font-bold uppercase text-xl">Công bố</h2>
              <span className="text-gray-400 inline-block">New York Times</span>
              <span className="text-gray-400 inline-block">
                An interview on Art & Design
              </span>
              <span className="text-gray-400 inline-block">
                An article on It’s Nice That
              </span>
              <span className="text-gray-400 inline-block">
                A post on Kinfolk Magazine
              </span>
            </div>
          </div>
          <div className="space-y-8">
            <img
              src="https://artorias.qodeinteractive.com/wp-content/uploads/2022/10/p-gallery-img-1.jpg"
              alt=""
            />
            <img
              src="https://artorias.qodeinteractive.com/wp-content/uploads/2022/10/p-gallery-img-4.jpg"
              alt=""
            />
            <img
              src="https://artorias.qodeinteractive.com/wp-content/uploads/2022/10/p-gallery-img-3.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
}
