import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { Layout } from "antd";
import SideNav from "@/componnents/sideNav";
import DonhangDetail from "./donhangDetail";
import { useDispatch, useSelector } from "react-redux";
import { getDonhangs } from "@/redux/features/donhang/donhangAction";
import { selectDonhangs } from "@/redux/features/donhang/donhangSlice";
import { Dropdown } from "antd";
import { updateDonhangAction } from "@/redux/features/donhang/donhangAction";
import FormattedDate from "../../componnents/formattedDate";

export default function Donhang() {
  const [selectedDonhang, setSelectedDonhang] = useState(null);
  const [collapsed, setCollapsed] = useState(true);
  const donhangs = useSelector(selectDonhangs);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDonhangs());
  }, [dispatch]);

  const handleUpdate = (status) => {
    dispatch(
      updateDonhangAction({ id: selectedDonhang.id, trangthai: status })
    );
  };

  const items = [
    {
      key: "1",
      label: (
        <span
          className="text-green-900 cursor-pointer"
          onClick={() => handleUpdate(0)}
        >
          Đơn mới
        </span>
      ),
    },
    {
      key: "2",
      label: (
        <span
          className=" text-green-500 cursor-pointer"
          onClick={() => handleUpdate(1)}
        >
          Nhận đơn
        </span>
      ),
    },
    {
      key: "3",
      label: (
        <span
          className=" text-green-500 cursor-pointer"
          onClick={() => handleUpdate(2)}
        >
          Đã giao
        </span>
      ),
    },
    {
      key: "4",
      label: (
        <span
          className=" text-red-500 cursor-pointer"
          onClick={() => handleUpdate(4)}
        >
          Huỷ đơn
        </span>
      ),
    },
  ];
  const columns = [
    {
      title: "Họ tên",
      dataIndex: "name",
      render: (text, record) => (
        <p
          className="cursor-pointer"
          onClick={() => {
            setSelectedDonhang(record);
            setCollapsed(false);
          }}
        >
          {record.name}
        </p>
      ),
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone",
      render: (text, record) => (
        <p
          className="cursor-pointer"
          onClick={() => {
            setSelectedDonhang(record);
            setCollapsed(false);
          }}
        >
          {record.phone}
        </p>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
    },
    {
      title: "Ngày tạo",
      dataIndex: "createAt",
      render: (text, record) => (
        <p
          className="cursor-pointer"
          onClick={() => {
            setSelectedDonhang(record);
            setCollapsed(false);
          }}
        >
          <FormattedDate dateString={record.createAt} />
        </p>
      ),
    },

    {
      title: "Trạng thái",
      dataIndex: "status",
      render: (text, record) => (
        <Dropdown
          menu={{
            items,
          }}
          placement="bottom"
          arrow
        >
          <div
            onClick={() => {
              setSelectedDonhang(record);
            }}
          >
            {record.trangthai === 0 ? (
              <span className="text-green-900 cursor-pointer">Đơn mới</span>
            ) : record.trangthai === 1 ? (
              <span className=" text-green-500 cursor-pointer">Nhận đơn</span>
            ) : record.trangthai === 2 ? (
              <span className=" text-green-500 cursor-pointer">Đã giao</span>
            ) : (
              <span className=" text-red-500 cursor-pointer">Huỷ đơn</span>
            )}
          </div>
        </Dropdown>
      ),
    },
  ];

  return (
    <Layout className="w-full h-full">
      <SideNav collapsed={collapsed}>
        <DonhangDetail donhangchitiets={selectedDonhang?.donhangchitiets} />
      </SideNav>
      {!collapsed && (
        <div
          className="w-full h-full fixed left-0 top-0 bg-black opacity-60 z-[99] cursor-pointer"
          onClick={() => {
            setCollapsed(true);
            setSelectedDonhang(null);
          }}
        ></div>
      )}

      <Layout>
        <div className="container h-full overflow-hidden">
          <div className="w-full h-full 2xl:mt-16">
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <h1 className="text-2xl font-bold text-black">Đơn hàng</h1>
              </div>
              <div>
                <Table
                  className="h-[80vh] overflow-auto relative"
                  columns={columns}
                  dataSource={donhangs.map((e, i) => {
                    return {
                      ...e,
                      key: i,
                    };
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </Layout>
  );
}
