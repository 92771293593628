import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Tabs } from "antd";
import useSlug from "@/hook/useSlug";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import { selectDanhmuc } from "@/redux/features/danhmuc/danhmucSlice";
import { deleteProduct } from "@/redux/features/product/productAction";
import { postProduct } from "@/redux/features/product/productAction";
import ModalUpload from "@/componnents/modalUpload";
import { updateProduct } from "@/redux/features/product/productAction";
import { selectTags } from "../../redux/features/tags/tagSlice";

const { Option } = Select;
const { TabPane } = Tabs;
const initialValues = {
  name: "",
  des: "",
  idDM: "",
  slug: "",
  tagIds: [],
  image: "",
  listImage: [],
  price: 0,
  priceSale: 0,
  ordering: 1,
  SKU: "",
  quantity: 1,
  width: "",
  height: "",
  depth: "",
  weight: "",
};
const validationSchema = Yup.object().shape({
  name: Yup.string().required("*Tên sản phẩm không được để trống"),
  des: Yup.string().required("*Miêu tả không được để trống"),
  idDM: Yup.string().required("Danh mục không được để trống"),
});

export default function ProductDetail({ product, handleCollapsed }) {
  const formikRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName, slug, setSlug] = useSlug();
  const [formProduct, setFormProduct] = useState(initialValues);
  const [tempSelectedImages, setTempSelectedImages] = useState([]);
  const danhmucSelector = useSelector(selectDanhmuc);
  const tagSelector = useSelector(selectTags);
  const dispatch = useDispatch();
  useEffect(() => {
    if (product) {
      product.tagIds = product?.tags?.map((e) => e.id);
      setName(product.name);
      setSlug(product.slug);
      setFormProduct(product);
      setTempSelectedImages(product.listImage);
    } else {
      setFormProduct(initialValues);
      setName(initialValues.name);
      setSlug(initialValues.slug);
      setTempSelectedImages([]);
    }
  }, [product, setName, setSlug, setFormProduct]);

  const handleSave = () => {
    if (formikRef.current) {
      formikRef.current.submitForm();
    }
  };
  const handleSubmit = (values) => {
    const danhmuc = danhmucSelector.find((x) => x.id === values.idDM);
    const filteredTags = tagSelector.filter((obj) =>
      values.tagIds.includes(obj.id)
    );

    values.danhmuc = danhmuc;
    values.tags = filteredTags;
    if (product?.id) {
      dispatch(updateProduct(values));
    } else {
      dispatch(postProduct(values));
    }
    handleCollapsed();
    // Xử lý khi form được submit
  };

  // Hàm xử lý khi click vào một hình ảnh
  const handleImageClick = (image) => {
    const selectedIndex = tempSelectedImages.indexOf(image);
    if (selectedIndex === -1) {
      setTempSelectedImages([...tempSelectedImages, image]);
    } else {
      const updatedSelection = [...tempSelectedImages];
      updatedSelection.splice(selectedIndex, 1);
      setTempSelectedImages(updatedSelection);
    }
  };

  const handleDeleteProduct = () => {
    dispatch(deleteProduct(product.id));
  };

  const handleOk = () => {
    setFormProduct({
      ...formProduct,
      listImage: tempSelectedImages,
    });
    setIsOpen(false);
  };

  const handleCancel = () => {
    setTempSelectedImages(formProduct.listImage);
    setIsOpen(false);
  };
  const handleReturn = () => {
    setTempSelectedImages(formProduct.listImage);
  };
  return (
    <div className="text-black 2xl:mt-16  p-5">
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-between w-full">
          {product ? (
            <div className="flex space-x-4">
              <img
                className="w-20 h-20 rounded-xl bg-gray-200 p-2"
                src={product.image}
                alt=""
              />
              <div className="space-y-2">
                <h2 className="text-2xl font-bold text-black">
                  {product.name}
                </h2>
                <span className="font-bold inline-block text-base">
                  Product Detail
                </span>
              </div>
            </div>
          ) : (
            <h2 className="text-2xl font-bold text-black">Products Detail</h2>
          )}

          <div className="flex items-center space-x-4">
            {formProduct.id && (
              <div
                className="flex items-center  rounded-2xl bg-black text-white hover:text-gray-400 cursor-pointer justify-center text-lg font-bold py-1 px-4 space-x-2"
                onClick={handleDeleteProduct}
              >
                <span className="material-symbols-outlined">delete</span>
                <span>Remove</span>
              </div>
            )}

            <div
              className="flex items-center  rounded-2xl bg-black text-white hover:text-gray-400 cursor-pointer justify-center text-lg font-bold py-1 px-4 space-x-2"
              onClick={handleSave}
            >
              <span className="material-symbols-outlined">save</span>
              <span>Save</span>
            </div>
          </div>
        </div>
      </div>

      <div style={{ padding: 20 }}>
        <Formik
          innerRef={formikRef}
          initialValues={{ ...formProduct, name, slug }}
          validationSchema={validationSchema}
          enableReinitialize={true} // Thêm dòng này
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values }) => {
            return (
              <Form className="space-y-6">
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Thông tin sản phẩm" key="1">
                    <div className="mt-6 space-y-6 text-lg">
                      <div className="flex flex-col space-y-2 ">
                        <label htmlFor="name">
                          Tên
                          <span className="underline decoration-dotted inline-block ml-1">
                            *
                          </span>
                        </label>
                        <Field
                          type="name"
                          id="name"
                          name="name"
                          value={name}
                          className="bg-transparent  border border-gray-400 p-3"
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        />
                        <ErrorMessage
                          name="name"
                          className="text-red-500"
                          component="div"
                        />
                      </div>
                      <div className="flex flex-col space-y-2 ">
                        <label htmlFor="name">
                          Slug
                          <span className="underline decoration-dotted inline-block ml-1">
                            *
                          </span>
                        </label>
                        <Field
                          type="slug"
                          id="slug"
                          name="slug"
                          value={slug}
                          onChange={(e) => {
                            setSlug(e.target.value);
                          }}
                          className="bg-transparent  border border-gray-400 p-3"
                        />
                        <ErrorMessage
                          name="slug"
                          className="text-red-500"
                          component="div"
                        />
                      </div>
                      <div className="flex flex-col space-y-2">
                        <label htmlFor="des">Miêu tả</label>
                        <Field
                          type="des"
                          id="des"
                          name="des"
                          className="bg-transparent  border border-gray-400 p-3"
                        />
                        <ErrorMessage
                          name="des"
                          className="text-red-500"
                          component="div"
                        />
                      </div>
                      <div className="flex flex-col space-y-2">
                        <label htmlFor="idDM">Danh mục</label>
                        <Field name="idDM" className="border-none">
                          {({ field }) => (
                            <Select
                              style={{
                                height: "54px",
                              }}
                              value={values.idDM}
                              placeholder="Chọn danh mục"
                              className="bg-transparent  border border-gray-400 w-full text-xl"
                              onChange={(value) => setFieldValue("idDM", value)}
                            >
                              {danhmucSelector.map((e, i) => (
                                <Option value={e.id} key={i}>
                                  {e.title}
                                </Option>
                              ))}
                            </Select>
                          )}
                        </Field>
                        <ErrorMessage
                          name="idDM"
                          className="text-red-500"
                          component="div"
                        />
                      </div>

                      <div className="flex flex-col space-y-2">
                        <label htmlFor="des">Tags</label>
                        <Field name="tagIds" className="border-none">
                          {({ field }) => (
                            <Select
                              mode="multiple"
                              style={{
                                height: "54px",
                              }}
                              value={values.tagIds}
                              placeholder="Chọn tags"
                              className="bg-transparent  border border-gray-400 w-full text-xl"
                              onChange={(value) =>
                                setFieldValue("tagIds", value)
                              }
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {tagSelector.map((e, i) => (
                                <Option value={e.id} key={i}>
                                  {e.title}
                                </Option>
                              ))}
                            </Select>
                          )}
                        </Field>
                        <ErrorMessage
                          name="tagIds"
                          className="text-red-500"
                          component="div"
                        />
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tab="Hình ảnh" key="2">
                    <div className="grid grid-cols-4 gap-4">
                      <div
                        className="relative bg-gray-200 group h-60 rounded-2xl flex items-center justify-center cursor-pointer"
                        onClick={() => {
                          setIsOpen(true);
                        }}
                      >
                        <span className="material-symbols-outlined text-5xl">
                          upload
                        </span>
                      </div>
                      {formProduct.listImage.map((e, i) => (
                        <div
                          key={i}
                          className="relative bg-gray-200 group h-60 p-4 rounded-2xl flex items-center justify-center"
                        >
                          <img
                            src={e}
                            className="w-full h-full object-contain "
                            alt=""
                          />

                          <span
                            onClick={() => {
                              setFormProduct({
                                ...formProduct,
                                image: e,
                              });
                            }}
                            className={`material-symbols-outlined absolute right-2 top-2 text-3xl cursor-pointer text-yellow-500 group-hover:opacity-100 opacity-0 ${
                              formProduct.image === e ? "opacity-100" : ""
                            }`}
                          >
                            star
                          </span>
                        </div>
                      ))}
                    </div>
                  </TabPane>
                  <TabPane tab="Giá" key="3">
                    <div className="mt-6 space-y-6 text-lg">
                      <div className="flex flex-col space-y-2 ">
                        <label htmlFor="price">
                          Giá
                          <span className="underline decoration-dotted inline-block ml-1">
                            *
                          </span>
                        </label>
                        <Field
                          type="number"
                          id="price"
                          name="price"
                          className="bg-transparent  border border-gray-400 p-3"
                        />
                        <ErrorMessage
                          name="price"
                          className="text-red-500"
                          component="div"
                        />
                      </div>
                      <div className="flex flex-col space-y-2 ">
                        <label htmlFor="priceSale">
                          Giá Sale
                          <span className="underline decoration-dotted inline-block ml-1">
                            *
                          </span>
                        </label>
                        <Field
                          type="priceSale"
                          id="priceSale"
                          name="priceSale"
                          className="bg-transparent  border border-gray-400 p-3"
                        />
                        <ErrorMessage
                          name="priceSale"
                          className="text-red-500"
                          component="div"
                        />
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tab="Hàng tồn kho" key="4">
                    <div className="mt-6 space-y-6 text-lg">
                      <div className="flex flex-col space-y-2 ">
                        <label htmlFor="SKU">
                          SKU
                          <span className="underline decoration-dotted inline-block ml-1">
                            *
                          </span>
                        </label>
                        <Field
                          type="SKU"
                          id="SKU"
                          name="SKU"
                          className="bg-transparent  border border-gray-400 p-3"
                        />
                        <ErrorMessage
                          name="SKU"
                          className="text-red-500"
                          component="div"
                        />
                      </div>
                      <div className="flex flex-col space-y-2 ">
                        <label htmlFor="quantity">
                          Số lượng
                          <span className="underline decoration-dotted inline-block ml-1">
                            *
                          </span>
                        </label>
                        <Field
                          type="number"
                          id="quantity"
                          name="quantity"
                          className="bg-transparent  border border-gray-400 p-3"
                        />
                        <ErrorMessage
                          name="quantity"
                          className="text-red-500"
                          component="div"
                        />
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tab="Khối lượng" key="5">
                    <div className="mt-6 space-y-6 text-lg">
                      <div className="flex items-center justify-between space-x-4">
                        <div className="flex flex-col space-y-2 w-full ">
                          <label htmlFor="width">
                            Chiều rộng
                            <span className="underline decoration-dotted inline-block ml-1">
                              *
                            </span>
                          </label>
                          <Field
                            type="text"
                            id="width"
                            name="width"
                            className="bg-transparent  border border-gray-400 p-3"
                          />
                          <ErrorMessage
                            name="width"
                            className="text-red-500"
                            component="div"
                          />
                        </div>
                        <div className="flex flex-col space-y-2 w-full ">
                          <label htmlFor="height">
                            Chiều cao
                            <span className="underline decoration-dotted inline-block ml-1">
                              *
                            </span>
                          </label>
                          <Field
                            type="text"
                            id="height"
                            name="height"
                            className="bg-transparent  border border-gray-400 p-3"
                          />
                          <ErrorMessage
                            name="height"
                            className="text-red-500"
                            component="div"
                          />
                        </div>
                        <div className="flex flex-col space-y-2 w-full ">
                          <label htmlFor="depth">
                            Chiều sâu
                            <span className="underline decoration-dotted inline-block ml-1">
                              *
                            </span>
                          </label>
                          <Field
                            type="text"
                            id="depth"
                            name="depth"
                            className="bg-transparent  border border-gray-400 p-3"
                          />
                          <ErrorMessage
                            name="depth"
                            className="text-red-500"
                            component="div"
                          />
                        </div>
                      </div>

                      <div className="flex flex-col space-y-2 ">
                        <label htmlFor="weight">
                          Cân nặng
                          <span className="underline decoration-dotted inline-block ml-1">
                            *
                          </span>
                        </label>
                        <Field
                          type="text"
                          id="weight"
                          name="weight"
                          className="bg-transparent  border border-gray-400 p-3"
                        />
                        <ErrorMessage
                          name="weight"
                          className="text-red-500"
                          component="div"
                        />
                      </div>
                    </div>
                  </TabPane>
                </Tabs>
              </Form>
            );
          }}
        </Formik>
        <ModalUpload
          isOpen={isOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
          handleImageClick={handleImageClick}
          listImage={tempSelectedImages}
          handleReturn={handleReturn}
        />
      </div>
    </div>
  );
}
