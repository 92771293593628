import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { selectUser } from "@/redux/features/user/userSlice";
import {
  handleSignInFacebook,
  handleSignInGoogle,
} from "../../redux/features/user/userAction";
export default function Login() {
  const dispatch = useDispatch();
  const userSelector = useSelector(selectUser);
  const [isRegister, setIsRegister] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (userSelector) {
      navigate("/");
    }
  }, [userSelector, navigate]);
  const loginGoogle = async () => {
    dispatch(handleSignInGoogle());
  };
  const loginFacebook = async () => {
    dispatch(handleSignInFacebook());
  };

  return (
    <>
      {/* <div>{userSelector?.displayName}</div> */}
      <div className="flex flex-col items-center justify-center space-y-10 fixed w-full  h-[100vh] translate-center bg-black p-6">
        {isRegister ? (
          <div className="space-y-10 ">
            <div className="space-y-6">
              <h1 className="md:text-3xl text-2xl text-center ">
                Khám phá Thế Giới Đồ Chơi
                <br />{" "}
                <span className="inline-block mt-3"> sieunhanvaanime.vn! </span>
              </h1>
              <p className="md:text-lg text-center">
                Đăng ký ngay để trải nghiệm mua sắm đỉnh cao!
              </p>
            </div>
            <div className="space-y-4 flex flex-col items-center">
              <button
                className="space-x-4 button-submit flex items-center  justify-start md:!w-[450px] !w-full !px-0"
                onClick={loginGoogle}
              >
                <svg
                  className="md:w-8 md:h-8 w-6 h-6"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fab"
                  data-icon="google"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 488 512"
                >
                  <path
                    fill="#9ca3af"
                    d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
                  ></path>
                </svg>
                <span className="font-bold md:text-base text-sm  text-gray-400 text-nowrap">
                  Đăng ký bằng Google
                </span>
              </button>
              <button
                className="space-x-4 button-submit flex items-center  justify-start  md:!w-[450px] !w-full !px-0"
                onClick={loginFacebook}
              >
                <svg
                  className="md:w-9 md:h-9 w-7 h-7"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fill="#9ca3af"
                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  ></path>
                </svg>
                <span className="font-bold md:text-base text-sm text-gray-400 text-nowrap">
                  Đăng ký bằng Facebook
                </span>
              </button>
              <p className="text-center">
                Bạn đã có tài khoản?
                <span
                  className="underline inline-block ml-2 cursor-pointer"
                  onClick={() => {
                    setIsRegister(false);
                  }}
                >
                  Đăng nhập
                </span>
              </p>
            </div>
          </div>
        ) : (
          <div className="space-y-10 ">
            <div className="space-y-6">
              <h1 className="md:text-3xl text-2xl text-center ">
                Chào mừng bạn trở lại
                <br />{" "}
                <span className="inline-block mt-3"> sieunhanvaanime.vn! </span>
              </h1>
              <p className="md:text-lg text-center">
                Đăng nhập để khám phá và nhận ưu đãi đặc biệt!
              </p>
            </div>

            <div className="space-y-4 flex flex-col items-center">
              <button
                className="space-x-4 button-submit flex items-center  justify-start md:!w-[450px] !w-full !px-0"
                onClick={loginGoogle}
              >
                <svg
                  className="md:w-8 md:h-8 w-6 h-6"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fab"
                  data-icon="google"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 488 512"
                >
                  <path
                    fill="#9ca3af"
                    d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
                  ></path>
                </svg>
                <span className="font-bold md:text-base text-sm  text-gray-400 text-nowrap">
                  Đăng nhập bằng Google
                </span>
              </button>
              <button
                className="space-x-4 button-submit flex items-center  justify-start  md:!w-[450px] !w-full !px-0"
                onClick={loginFacebook}
              >
                <svg
                  className="md:w-9 md:h-9 w-7 h-7"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fill="#9ca3af"
                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  ></path>
                </svg>
                <span className="font-bold md:text-base text-sm text-gray-400 text-nowrap">
                  Đăng nhập bằng Facebook
                </span>
              </button>
              <p className="text-center">
                Bạn chưa có tài khoản?
                <span
                  className="underline inline-block ml-2 cursor-pointer"
                  onClick={() => {
                    setIsRegister(true);
                  }}
                >
                  Đăng ký
                </span>
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
