import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import Sidebar from "./sideBar";
import { getTags } from "@/redux/features/tags/tagAction";

export default function AdminLayout() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTags());
  }, [dispatch]);
  return (
    <div className="flex w-full h-[100vh] bg-[#f5f5f5]">
      <Sidebar />
      <div className="w-full h-full p-5 text-black">
        <Outlet />
      </div>
    </div>
  );
}
