import React from "react";

export default function Email() {
  return (
    <section className="space-y-10">
      <div className="flex md:space-x-10 items-center justify-between md:flex-row flex-col relative pl-10 md:space-y-0 space-y-6">
        <span className="uppercase text-lg  absolute md:-left-6  -left-8 top-1/2 md:top-10 translate-rotate">
          JOIN US
        </span>
        <div className="space-y-2 md:w-1/3 w-full">
          <h2 className="md:text-3xl text-2xl font-bold uppercase">
            <span className="qodef-m-title-inner">
              đăng ký để nhận những ưu đãi mới nhất
            </span>
          </h2>
        </div>
        <div className="flex md:flex-row flex-col items-center md:space-x-8 space-y-6 md:space-y-0 md:w-2/3 w-full">
          <input
            className="email-input w-full"
            placeholder="Your email here..."
          />
          <button className="button-submit text-nowrap">Đăng ký</button>
        </div>
      </div>
    </section>
  );
}
