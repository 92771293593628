import React, { useEffect, useState } from "react";
import Breadcrumb from "@/componnents/breadcrumb";
import useCurrency from "@/hook/useCurrency";
import { Link, useNavigate, useParams } from "react-router-dom";
import ProductList from "@/componnents/productList";
import { useDispatch } from "react-redux";
import { handleQuantity } from "@/redux/features/cart/cartAction";
import ProductService from "@/service/product";

export default function ProductDetail() {
  const { slug } = useParams();
  const [productDetail, setProductDetail] = useState(null);
  const [productRelate, setProductRelate] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formatCurrency = useCurrency();
  const [tab, setTab] = useState(1);
  const [quantity, setQuantity] = useState(1);
  const data = [
    {
      name: "Sản phẩm",
      link: "san-pham",
    },
  ];
  useEffect(() => {
    ProductService.getProductDetail(slug)
      .then((data) => {
        if (data) {
          setProductDetail(data);
          ProductService.getProductRelate(data.danhmuc.slug).then((x) => {
            if (x) {
              setProductRelate(x);
            }
          });
        } else {
          navigate("/404");
        }
      })
      .catch((error) => {
        console.error("Error fetching product details:", error);
      });
  }, [slug, navigate]);

  const handleIncrease = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrease = () => {
    setQuantity((prevQuantity) => (prevQuantity > 1 ? prevQuantity - 1 : 1));
  };

  const handleChange = (event) => {
    const value = parseInt(event.target.value, 10);
    if (!isNaN(value) && value > 0) {
      setQuantity(value);
    } else {
      setQuantity(1);
    }
  };
  const handleAddToCart = () => {
    const item = { ...productDetail };
    item.cartNum = quantity;
    dispatch(handleQuantity(item));
  };
  return (
    <>
      {productDetail && (
        <>
          {" "}
          <Breadcrumb
            data={[
              ...data,
              {
                name: productDetail.name,
                link: productDetail.slug,
              },
            ]}
          />
          <div className="lg:flex lg:space-x-20">
            <div className="lg:w-3/5 md:flex md:space-x-5 md:space-y-0 space-y-3">
              <div className="md:w-3/4 h-full">
                <img
                  src={productDetail.image}
                  className={`px-1 border border-gray-300 object-contain ${
                    productDetail.quantity === 0 && "mix-blend-luminosity	"
                  }`}
                  alt=""
                />
              </div>
              <div className="md:w-1/4  grid md:grid-cols-1 grid-cols-2 gap-3">
                {productDetail.listImage.map((e, i) => (
                  <div
                    className="w-full h-full px-1 border border-gray-300 "
                    key={i}
                  >
                    <img
                      src={e}
                      className="object-contain bg-gradient"
                      alt=""
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="lg:w-2/5 space-y-10 pt-10">
              <div className="space-y-4">
                <h1 className=" text-3xl uppercase font-bold">
                  {productDetail.name}
                </h1>
                <span className="inline-block font-medium md:text-2xl text-xl">
                  {formatCurrency(productDetail.price)}
                </span>
                <p className="text-gray-400 text-justify">
                  {productDetail.des}
                </p>
              </div>
              <div className="flex md:flex-row flex-col md:items-center md:space-x-4 md:space-y-0 space-y-4">
                <div className="w-24 relative">
                  <span
                    className="absolute top-0 w-8 h-1/2 text-center right-0 border border-gray-300 cursor-pointer"
                    onClick={handleIncrease}
                  >
                    +
                  </span>
                  <span
                    className="absolute bottom-0 w-8 h-1/2 text-center right-0 border border-gray-300 cursor-pointer"
                    onClick={handleDecrease}
                  >
                    -
                  </span>
                  <input
                    type="text"
                    value={quantity}
                    onChange={handleChange}
                    className="inline-block text-center px-2 py-3 text-lg w-16 border border-gray-300 bg-transparent"
                  />
                </div>
                <button
                  className="button-submit text-nowrap"
                  onClick={handleAddToCart}
                >
                  add to cart
                </button>
              </div>
              <div className="space-y-2">
                <h2 className="text-xl uppercase font-bold">Danh mục:</h2>
                <Link
                  to={`/danh-muc/${productDetail.danhmuc.slug}`}
                  className="inline-block text-gray-400 hover:text-white  cursor-pointer font-light"
                >
                  {productDetail.danhmuc.title}
                </Link>
              </div>
              {productDetail.tags.length > 0 && (
                <div className="space-y-2">
                  <h2 className="text-xl uppercase font-bold">TAGS:</h2>
                  <div className="space-x-2 text-gray-300">
                    {productDetail.tags.map((e, i) => (
                      <span className="line-through hover:no-underline cursor-pointer text-gray-400 pr-2">
                        {e.title}
                      </span>
                    ))}
                  </div>
                </div>
              )}

              <div className="space-y-2">
                <h2 className="text-xl uppercase font-bold">SKU:</h2>
                <div className="space-x-2 text-gray-300">
                  <span className="text-gray-400">{productDetail.SKU}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="space-y-8">
            <div className="w-full flex md:flex-row flex-col md:space-y-0 space-y-4">
              <button
                className={`uppercase font-bold px-10 md:w-auto w-full py-4 border border-gray-400 text-nowrap button-review ${
                  tab === 1 ? "active" : ""
                }`}
                onClick={() => setTab(1)}
              >
                Description
              </button>
              <button
                className={`uppercase font-bold px-10 py-4 border border-gray-400 text-nowrap button-review ${
                  tab === 2 ? "active" : ""
                }`}
                onClick={() => setTab(2)}
              >
                Additional information
              </button>
            </div>
            {tab === 1 ? (
              <p className="text-gray-400 text-justify">{productDetail.des}</p>
            ) : (
              <div className="space-y-4">
                <div className="">
                  <span className="inline-block md:text-xl font-bold uppercase md:w-48 w-40">
                    Weight:
                  </span>
                  <span className="font-light text-gray-400">4.5 kg</span>
                </div>
                <div className="">
                  <span className="inline-block md:text-xl font-bold uppercase md:w-48 w-40">
                    DIMENSIONS:
                  </span>
                  <span className="font-light text-gray-400">
                    {productDetail.height +
                      " x " +
                      productDetail.width +
                      " x " +
                      productDetail.depth}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="space-y-8">
            <h2 className="md:text-3xl text-2xl font-bold uppercase">
              Sản phẩm liên quan
            </h2>
            <ProductList products={productRelate} />
          </div>
        </>
      )}
    </>
  );
}
