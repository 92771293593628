import ImageService from "@/service/images";
import {
  setError,
  setLoading,
  setImages,
  deleteImageSlice,
  postData,
  updateImage,
} from "./imageSlice";

export const getImages = (danhmuc) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const result = await ImageService.getImages(danhmuc);
    await dispatch(setImages(result));
  } catch (error) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const postImage = (file, danhmuc) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const result = await ImageService.postImage(file, danhmuc);
    dispatch(postData(result));
  } catch (error) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};
export const updateData = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const result = await ImageService.updateImage(data);
    dispatch(updateImage(result));
  } catch (error) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const deleteImage = (id) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    ImageService.deleteImage(id);
    dispatch(deleteImageSlice(id));
  } catch (error) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};
