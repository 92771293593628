import React from "react";
import { Link } from "react-router-dom";

export default function Error() {
  return (
    <div className="flex flex-col items-center justify-center space-y-10 fixed  h-[100vh] translate-center bg-black w-full p-6">
      <h1 className="text-3xl font-bold uppercase">ERROR PAGE</h1>
      <p>
        The page you are looking for doesn't exist. It may have been moved or
        removed altogether. Please try searching for some other page, or return
        to the website's homepage to find what you're looking for.
      </p>
      <Link to="/" className="button-submit text-nowrap">
        back to home
      </Link>
    </div>
  );
}
