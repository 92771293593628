import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import useCurrency from "../hook/useCurrency";
import { handleAddCart } from "../redux/features/cart/cartAction";

export default function ProductList({ products }) {
  const dispatch = useDispatch();
  // const price = useCurrency(2000000);
  const formatCurrency = useCurrency();

  return (
    <div className="grid lg:grid-cols-3  md:grid-cols-2 gap-8">
      {products?.map((e, i) => (
        <div
          className="flex flex-col items-center space-y-4 product-item pb-2"
          key={i}
        >
          <Link to={`/san-pham/${e.slug}`} className="relative">
            <img
              className={`border border-gray-300 object-contain ${
                e.quantity === 0 && "mix-blend-luminosity	"
              }`}
              loading="lazy"
              src={e.image}
              alt=""
            />
            {e.quantity === 0 && (
              <span className="absolute left-5 top-5  text-gray-400 uppercase text-lg font-bold">
                Hết hàng
              </span>
            )}
          </Link>
          <h3 className="text-lg font-bold uppercase leading-7 text-center px-10">
            {e.name}
          </h3>
          <span
            className={` z-1 min-w-32 text-center ${
              e.quantity > 0 && "price-product"
            }`}
            onClick={() => {
              e.quantity > 0 && dispatch(handleAddCart(e));
            }}
          >
            {formatCurrency(e.price)}
          </span>
        </div>
      ))}
    </div>
  );
}
