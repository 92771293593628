import React, { useEffect } from "react";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";
export default function Gallery() {
  useEffect(() => {
    let lightbox = new PhotoSwipeLightbox({
      gallery: "#my-gallery",
      children: "a",
      pswpModule: () => import("photoswipe"),
    });
    lightbox.init();

    return () => {
      lightbox.destroy();
      lightbox = null;
    };
  }, []);

  return (
    <section className="space-y-10">
      <div className="flex space-x-10 items-center justify-between relative md:pl-10 pl-2 ">
        <span className="uppercase text-lg  absolute -left-8 top-12 translate-rotate">
          PHOTOS
        </span>
        <div className="space-y-2">
          <h2 className="md:text-3xl text-xl font-bold uppercase">
            góc nhỏ trưng bày
          </h2>
          <p className="text-justify pr-10 ">
            Góc nhỏ trưng bày mô hình Bandai của khách hàng là nơi thể hiện niềm
            đam mê và sự tận tâm đối với mô hình.{" "}
          </p>
          <p>
            Từng sản phẩm được sắp xếp cẩn thận, tạo nên một không gian sống
            động và đầy màu sắc, mang lại cảm giác phấn khích và niềm vui mỗi
            khi ngắm nhìn.
          </p>
        </div>
      </div>
      <div className="-m-1 flex flex-wrap md:-m-2" id="my-gallery">
        <div className="flex md:w-1/2 flex-wrap">
          <a
            className="w-1/2 p-1 md:p-2"
            target="_blank"
            rel="noreferrer"
            data-pswp-width={800}
            data-pswp-height={600}
            href="https://tecdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(70).webp"
          >
            <img
              alt="gallery"
              className="block h-full w-full rounded-lg object-cover object-center"
              loading="lazy"
              src="https://tecdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(70).webp"
            />
          </a>
          <a
            className="w-1/2 p-1 md:p-2"
            target="_blank"
            rel="noreferrer"
            data-pswp-width={800}
            data-pswp-height={600}
            href="https://tecdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(72).webp"
          >
            <img
              alt="gallery"
              className="block h-full w-full rounded-lg object-cover object-center"
              loading="lazy"
              src="https://tecdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(72).webp"
            />
          </a>
          <a
            className="w-full p-1 md:p-2"
            target="_blank"
            rel="noreferrer"
            data-pswp-width={800}
            data-pswp-height={600}
            href="https://tecdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp"
          >
            <img
              alt="gallery"
              className="block h-full w-full rounded-lg object-cover object-center"
              loading="lazy"
              src="https://tecdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp"
            />
          </a>
        </div>
        <div className="flex md:w-1/2 flex-wrap">
          <a
            className="w-full p-1 md:p-2"
            target="_blank"
            rel="noreferrer"
            data-pswp-width={800}
            data-pswp-height={600}
            href="https://tecdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(74).webp"
          >
            <img
              alt="gallery"
              className="block h-full w-full rounded-lg object-cover object-center"
              loading="lazy"
              src="https://tecdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(74).webp"
            />
          </a>
          <a
            className="w-1/2 p-1 md:p-2"
            target="_blank"
            rel="noreferrer"
            data-pswp-width={800}
            data-pswp-height={600}
            href="https://tecdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(75).webp"
          >
            <img
              alt="gallery"
              className="block h-full w-full rounded-lg object-cover object-center"
              loading="lazy"
              src="https://tecdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(75).webp"
            />
          </a>
          <a
            className="w-1/2 p-1 md:p-2"
            target="_blank"
            rel="noreferrer"
            data-pswp-width={800}
            data-pswp-height={600}
            href="https://tecdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(77).webp"
          >
            <img
              alt="gallery"
              className="block h-full w-full rounded-lg object-cover object-center"
              loading="lazy"
              src="https://tecdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(77).webp"
            />
          </a>
        </div>
      </div>
    </section>
  );
}
