import { addCart, decrease, increase, remove, quantity, clearCart, setLoading, setError } from './cartSlice';

export const handleAddCart = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    dispatch(addCart(data)); 
  } catch (error) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const handleDecrease = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    // const response = await api.decrease(data);
    // dispatch(decrease(response.data));
    dispatch(decrease(data)); // Trả về trực tiếp cho ví dụ này
  } catch (error) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const handleIncrease = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    // const response = await api.increase(data);
    // dispatch(increase(response.data));
    dispatch(increase(data)); // Trả về trực tiếp cho ví dụ này
  } catch (error) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const handleRemove = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    // const response = await api.remove(data);
    // dispatch(remove(response.data));
    dispatch(remove(data)); // Trả về trực tiếp cho ví dụ này
  } catch (error) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const handleQuantity = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    // const response = await api.quantity(data);
    // dispatch(quantity(response.data));
    dispatch(quantity(data)); // Trả về trực tiếp cho ví dụ này
  } catch (error) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const handleClearCart = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    console.log('ssss');
    // const response = await api.clearCart();
    // dispatch(clearCart(response.data));
    dispatch(clearCart()); // Trả về trực tiếp cho ví dụ này
  } catch (error) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};