import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductList from "@/componnents/productList";
import { selectProductPopular } from "@/redux/features/product/productSlice";
import { getProductPopular } from "@/redux/features/product/productAction";

export default function Product() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProductPopular());
  }, [dispatch]);
  const productPopular = useSelector(selectProductPopular);
  return (
    <section className="space-y-10">
      <div className="flex space-x-10 items-center justify-between relative md:pl-10 pl-2">
        <span className="uppercase text-lg  absolute -left-6 top-8 translate-rotate">
          SHOP
        </span>
        <div className="space-y-2">
          <h2 className="md:text-3xl text-xl font-bold uppercase">
            mô hình bán chạy
          </h2>
          <p>
            Bandai, nhà sản xuất đồ chơi hàng đầu, tự hào mang đến những sản
            phẩm đỉnh cao về sáng tạo và chất lượng.
          </p>
          <p>
            Với sự tỉ mỉ và chăm chút trong từng chi tiết, chúng tôi cam kết
            mang lại niềm vui và sự hài lòng tuyệt đối cho khách hàng.
          </p>
        </div>
      </div>
      <ProductList products={productPopular} />
    </section>
  );
}
