import { api } from "./config";
const ProductService = {
  getProducts(
    slug = "beef",
    page = 1,
    limit = 10,
    sortBy = "",
    minPrice = 100000,
    maxPrice = 4000000,
    tagId = ""
  ) {
    return api.get(
      `/product?slug=${slug}&page=${page}&limit=${limit}&sortBy=${sortBy}&minPrice=${minPrice}&maxPrice=${maxPrice}&tag=${tagId}`
    );
  },
  getProductPopular() {
    return api.get("/product/popular");
  },
  getProductAll() {
    return api.get("/product/all");
  },
  getProductRelate(slug) {
    return api.get(`/product/relate?slug=${slug}`);
  },
  getProductDetail(slug) {
    return api.get(`/product/${slug}`);
  },
  searchProducts(keywork) {
    return api.get(`/product/search?search=${keywork}`);
  },
  postProduct(data) {
    return api.token().post(`/product`, data);
  },
  deleteProduct(id) {
    return api.token().delete(`/product/${id}`);
  },
  updateProduct(data) {
    return api.token().patch(`/product/${data.id}`, data);
  },
};
export default ProductService;
