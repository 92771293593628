import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { selectProductAll } from "@/redux/features/product/productSlice";
import { getProductAll } from "@/redux/features/product/productAction";
import { Layout } from "antd";
import useCurrency from "@/hook/useCurrency";
import ProductDetail from "./productDetail";
import SideNav from "@/componnents/sideNav";
import { updateProduct } from "@/redux/features/product/productAction";
export default function Product() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectProduct, setSelectProduct] = useState(null);
  const products = useSelector(selectProductAll);
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(true);
  const formatCurrency = useCurrency();

  useEffect(() => {
    dispatch(getProductAll());
  }, [dispatch]);

  const columns = [
    {
      title: "Hình",
      dataIndex: "image",
      render: (text, record) => (
        <img
          className="cursor-pointer w-16 h-16 object-contain"
          src={record.image}
          alt={record.slug}
        />
      ),
    },
    {
      title: "Sản phẩm",
      dataIndex: "name",
      render: (text, record) => (
        <p
          className="cursor-pointer"
          onClick={() => {
            setSelectProduct(record);
            setCollapsed(false);
          }}
        >
          {record.name}
        </p>
      ),
    },
    {
      title: "Danh mục",
      dataIndex: "danhmuc",
      render: (text, record) => <span>{record.danhmuc.title}</span>,
    },
    {
      title: "Giá",
      dataIndex: "price",
      render: (text, record) => <span>{formatCurrency(record.price)}</span>,
    },

    {
      title: "Loại",
      dataIndex: "type",
      render: (text, record) => (
        <span
          className="cursor-pointer underline"
          onClick={() => {
            handleUpdate(record);
          }}
        >
          {record.type === 1 ? "Sản phẩm bán chạy" : "Bình thường"}
        </span>
      ),
    },
    {
      title: "Số lượng",
      dataIndex: "quantity",
    },
    {
      title: "Active",
      dataIndex: "published",
      render: (text, record) => (
        <span
          className={`material-symbols-outlined ${
            record.published === 1 ? "text-green-500" : "text-red-500"
          } cursor-pointer`}
          onClick={() => {
            handlePublished(record);
          }}
        >
          {record.published === 1 ? " check_circle" : "cancel"}
        </span>
      ),
    },
  ];
  const handleUpdate = (data) => {
    data.type = data.type === 1 ? 0 : 1;
    dispatch(updateProduct(data));
  };
  const handlePublished = (data) => {
    data.published = data.published === 1 ? 0 : 1;
    dispatch(updateProduct(data));
  };
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;
  const handleCollapsed = () => {
    setCollapsed(true);
  };
  return (
    <Layout className="w-full h-full">
      <SideNav collapsed={collapsed}>
        <ProductDetail
          product={selectProduct}
          handleCollapsed={handleCollapsed}
        />
      </SideNav>
      {!collapsed && (
        <div
          className="w-full h-full fixed left-0 top-0 bg-black opacity-60 z-[99] cursor-pointer"
          onClick={() => {
            setCollapsed(true);
            setSelectProduct(null);
          }}
        ></div>
      )}

      <Layout>
        <div className="container h-full overflow-hidden">
          <div className="w-full h-full 2xl:mt-16">
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <h1 className="text-2xl font-bold text-black">Sản phẩm</h1>
                <div
                  className="flex items-center  rounded-2xl bg-black text-white hover:text-gray-400 cursor-pointer justify-center text-lg font-bold py-1 px-4 space-x-2"
                  onClick={() => {
                    setCollapsed(!collapsed);
                  }}
                >
                  <span className="material-symbols-outlined">add</span>
                  <span>Add</span>
                </div>
              </div>
              <div>
                <div
                  style={{
                    marginBottom: 16,
                  }}
                >
                  <span
                    style={{
                      marginLeft: 8,
                    }}
                  >
                    {hasSelected
                      ? `Selected ${selectedRowKeys.length} items`
                      : ""}
                  </span>
                </div>
                <Table
                  className="h-[80vh] overflow-auto relative"
                  rowSelection={rowSelection}
                  columns={columns}
                  dataSource={products.map((e, i) => {
                    return {
                      ...e,
                      key: i,
                    };
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </Layout>
  );
}
