import React from "react";
import { Link } from "react-router-dom";

export default function Breadcrumb({ data }) {
  return (
    <div className="flex flex-col md:flex-row md:items-end space-y-4 justify-between border-b border-b-gray-300 pb-5 md:mt-10 mt-20 pt-20">
      <h1 className="text-3xl font-bold uppercase">Danh mục</h1>
      <span className="space-x-2 text-gray-400">
        <Link to="/">Trang chủ</Link>
        <span>/</span>
        {data.map((value, index) => {
          // If this is the last item in the path, don't make it a link
          if (index === data.length - 1) {
            return <span key={index}>{value.name}</span>;
          }

          return (
            <Link to={`/${value.link}`} key={index}>
              {value.name} /
            </Link>
          );
        })}
      </span>
    </div>
  );
}
