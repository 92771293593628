import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import { deleteDanhmuc } from "@/redux/features/danhmuc/danhmucAction";
import { postDanhmuc } from "@/redux/features/danhmuc/danhmucAction";
import ModalUpload from "@/componnents/modalUpload";
import { updateData } from "@/redux/features/danhmuc/danhmucAction";
import { selectTags } from "../../redux/features/tags/tagSlice";

const { Option } = Select;
const { TabPane } = Tabs;
const initialValues = {
  title: "",
  des: "",
  slug: "",
  image: "",
  tagIds: [],
  ordering: 1,
};
const validationSchema = Yup.object().shape({
  title: Yup.string().required("*Tên sản phẩm không được để trống"),
  des: Yup.string().required("*Miêu tả không được để trống"),
  slug: Yup.string().required("*Slug không được để trống"),
});

export default function DanhmucDetail({ danhmuc, handleCollapsed }) {
  const formikRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [formDanhmuc, setFormDanhmuc] = useState(initialValues);
  const [selectedImage, setSelectedImage] = useState(null);
  const tagSelector = useSelector(selectTags);

  const dispatch = useDispatch();
  useEffect(() => {
    if (danhmuc) {
      danhmuc.tagIds =
        danhmuc.tags.legnth > 0 ? danhmuc?.tags?.map((e) => e.id) : [];

      setFormDanhmuc(danhmuc);
    } else {
      setFormDanhmuc(initialValues);
    }
  }, [danhmuc, setFormDanhmuc]);

  const handleSave = () => {
    if (formikRef.current) {
      formikRef.current.submitForm();
    }
  };
  const handleSubmit = (values) => {
    if (values.tagIds?.length > 0) {
      const filteredTags = tagSelector.filter((obj) =>
        values.tagIds.includes(obj.id)
      );
      values.tags = filteredTags;
    }

    if (danhmuc?.id) {
      dispatch(updateData(values));
    } else {
      dispatch(postDanhmuc(values));
      setFormDanhmuc(initialValues);
    }
    handleCollapsed();
    // Xử lý khi form được submit
  };

  // Hàm xử lý khi click vào một hình ảnh
  const handleSelectImage = (image) => {
    setSelectedImage(image);
  };

  const handleDeleteDanhmuc = () => {
    dispatch(deleteDanhmuc(danhmuc.id));
  };

  const handleOk = () => {
    setFormDanhmuc({
      ...formDanhmuc,
      image: selectedImage,
    });
    setSelectedImage(null);
    setIsOpen(false);
  };

  const handleCancel = () => {
    setSelectedImage(null);
    setIsOpen(false);
  };
  const handleReturn = () => {
    setSelectedImage(null);
  };
  return (
    <div className="text-black 2xl:mt-16  p-5">
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-between w-full">
          {danhmuc ? (
            <div className="flex space-x-4">
              <img
                className="w-20 h-20 rounded-xl bg-gray-200 p-2"
                src={danhmuc.image}
                alt=""
              />
              <div className="space-y-2">
                <h2 className="text-2xl font-bold text-black">
                  {danhmuc.title}
                </h2>
                <span className="font-bold inline-block text-base">
                  Chi tiết danh mục
                </span>
              </div>
            </div>
          ) : (
            <h2 className="text-2xl font-bold text-black">Chi tiết danh mục</h2>
          )}

          <div className="flex items-center space-x-4">
            {formDanhmuc.id && (
              <div
                className="flex items-center  rounded-2xl bg-black text-white hover:text-gray-400 cursor-pointer justify-center text-lg font-bold py-1 px-4 space-x-2"
                onClick={handleDeleteDanhmuc}
              >
                <span className="material-symbols-outlined">delete</span>
                <span>Remove</span>
              </div>
            )}

            <div
              className="flex items-center  rounded-2xl bg-black text-white hover:text-gray-400 cursor-pointer justify-center text-lg font-bold py-1 px-4 space-x-2"
              onClick={handleSave}
            >
              <span className="material-symbols-outlined">save</span>
              <span>Save</span>
            </div>
          </div>
        </div>
      </div>

      <div style={{ padding: 20 }}>
        <Formik
          innerRef={formikRef}
          initialValues={{ ...formDanhmuc }}
          validationSchema={validationSchema}
          enableReinitialize={true} // Thêm dòng này
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values }) => {
            return (
              <Form className="space-y-6">
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Thông tin sản phẩm" key="1">
                    <div className="mt-6 space-y-6 text-lg">
                      <div className="flex flex-col space-y-2 ">
                        <label htmlFor="title">
                          Tên
                          <span className="underline decoration-dotted inline-block ml-1">
                            *
                          </span>
                        </label>
                        <Field
                          type="title"
                          id="title"
                          name="title"
                          className="bg-transparent  border border-gray-400 p-3"
                        />
                        <ErrorMessage
                          name="title"
                          className="text-red-500"
                          component="div"
                        />
                      </div>
                      <div className="flex flex-col space-y-2 ">
                        <label htmlFor="slug">
                          Slug
                          <span className="underline decoration-dotted inline-block ml-1">
                            *
                          </span>
                        </label>
                        <Field
                          type="slug"
                          id="slug"
                          name="slug"
                          className="bg-transparent  border border-gray-400 p-3"
                        />
                        <ErrorMessage
                          name="slug"
                          className="text-red-500"
                          component="div"
                        />
                      </div>
                      <div className="flex flex-col space-y-2">
                        <label htmlFor="des">Miêu tả</label>
                        <Field
                          type="des"
                          id="des"
                          name="des"
                          className="bg-transparent  border border-gray-400 p-3"
                        />
                        <ErrorMessage
                          name="des"
                          className="text-red-500"
                          component="div"
                        />
                      </div>
                      <div className="flex flex-col space-y-2">
                        <label htmlFor="ordering">Sắp xếp</label>
                        <Field
                          type="number"
                          id="ordering"
                          name="ordering"
                          className="bg-transparent  border border-gray-400 p-3"
                        />
                        <ErrorMessage
                          name="ordering"
                          className="text-red-500"
                          component="div"
                        />
                      </div>

                      <div className="flex flex-col space-y-2">
                        <label htmlFor="des">Tags</label>
                        <Field name="tagIds" className="border-none">
                          {({ field }) => (
                            <Select
                              mode="multiple"
                              style={{
                                height: "54px",
                              }}
                              value={values.tagIds}
                              placeholder="Chọn tags"
                              className="bg-transparent  border border-gray-400 w-full text-xl"
                              onChange={(value) => setFieldValue("tagIds", value)}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {tagSelector.map((e, i) => (
                                <Option value={e.id} key={i}>
                                  {e.title}
                                </Option>
                              ))}
                            </Select>
                          )}
                        </Field>
                        <ErrorMessage
                          name="tagIds"
                          className="text-red-500"
                          component="div"
                        />
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tab="Hình ảnh" key="2">
                    <div className="grid grid-cols-4 gap-4">
                      <div
                        className="relative bg-gray-200 group h-60 rounded-2xl flex items-center justify-center cursor-pointer"
                        onClick={() => {
                          setIsOpen(true);
                        }}
                      >
                        <span className="material-symbols-outlined text-5xl">
                          upload
                        </span>
                      </div>
                      {formDanhmuc.image !== "" && (
                        <div className="relative bg-gray-200 group h-60 p-4 rounded-2xl flex items-center justify-center">
                          <img
                            src={formDanhmuc.image}
                            className="w-full h-full object-contain "
                            alt=""
                          />

                          <span className="material-symbols-outlined absolute right-2 top-2 text-3xl cursor-pointer text-yellow-500 ">
                            star
                          </span>
                        </div>
                      )}
                    </div>
                  </TabPane>
                </Tabs>
              </Form>
            );
          }}
        </Formik>
        <ModalUpload
          isOpen={isOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
          handleReturn={handleReturn}
          handleImageClick={handleSelectImage}
          listImage={[selectedImage]}
        />
      </div>
    </div>
  );
}
