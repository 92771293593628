import React, { useState } from "react";
import { Table } from "antd";
import { useSelector } from "react-redux";
import { Layout } from "antd";
import SideNav from "@/componnents/sideNav";
import DanhmucDetail from "./danhmucDetail";
import { selectDanhmuc } from "@/redux/features/danhmuc/danhmucSlice";

export default function Danhmuc() {
  const [selectedDanhmuc, setSelectedDanhmuc] = useState(null);
  const danhmucs = useSelector(selectDanhmuc);
  const [collapsed, setCollapsed] = useState(true);
  const columns = [
    {
      title: "Hình",
      dataIndex: "image",
      render: (text, record) => (
        <img
          className="cursor-pointer w-16 h-16 object-contain"
          src={record.image}
          alt={record.title}
          onClick={() => {
            setSelectedDanhmuc(record);
            setCollapsed(false);
          }}
        />
      ),
    },
    {
      title: "Tiêu đề",
      dataIndex: "title",
      render: (text, record) => (
        <p
          className="cursor-pointer"
          onClick={() => {
            setSelectedDanhmuc(record);
            setCollapsed(false);
          }}
        >
          {record.title}
        </p>
      ),
    },
    {
      title: "Active",
      dataIndex: "published",
      render: (text, record) =>
        record.published === 1 ? (
          <span className="material-symbols-outlined text-green-500">
            check_circle
          </span>
        ) : (
          <span className="material-symbols-outlined text-red-500">cancel</span>
        ),
    },
  ];
  const handleCollapsed = () => {
    setCollapsed(true);
  };



  return (
    <Layout className="w-full h-full">
      <SideNav collapsed={collapsed}>
        <DanhmucDetail
          danhmuc={selectedDanhmuc}
          handleCollapsed={handleCollapsed}
        />
      </SideNav>
      {!collapsed && (
        <div
          className="w-full h-full fixed left-0 top-0 bg-black opacity-60 z-[99] cursor-pointer"
          onClick={() => {
            setCollapsed(true);
            setSelectedDanhmuc(null);
          }}
        ></div>
      )}

      <Layout>
        <div className="container h-full overflow-hidden">
          <div className="w-full h-full 2xl:mt-16">
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <h1 className="text-2xl font-bold text-black">Danh mục</h1>
                <div
                  className="flex items-center  rounded-2xl bg-black text-white hover:text-gray-400 cursor-pointer justify-center text-lg font-bold py-1 px-4 space-x-2"
                  onClick={() => {
                    setCollapsed(!collapsed);
                  }}
                >
                  <span className="material-symbols-outlined">add</span>
                  <span>Add</span>
                </div>
              </div>
              <div>
                <Table
                  className="h-[80vh] overflow-auto relative"
                  // rowSelection={rowSelection}
                  columns={columns}
                  dataSource={danhmucs.map((e, i) => {
                    return {
                      ...e,
                      key: i,
                    };
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </Layout>
  );
}
