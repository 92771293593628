import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className=" border border-gray-300 container !py-10 ">
      <div className="flex flex-col items-center space-y-16">
        <div className="w-56">
          <img
            src="https://artorias.qodeinteractive.com/wp-content/uploads/2022/11/footer-logo-img.png"
            alt=""
          />
        </div>
        <div className="space-y-10">
          <ul className="md:text-lg uppercase font-bold flex md:flex-row flex-col  md:space-y-0 space-y-4 items-center md:space-x-12">
            <li className="cursor-pointer whitespace-nowrap">
              <Link to="/">trang chủ</Link>
            </li>
            <li className="cursor-pointer whitespace-nowrap">
              <Link to="/ve-chung-toi-us">Về chúng tôi</Link>
            </li>
            <li className="cursor-pointer whitespace-nowrap">
              <Link to="/danh-muc">Danh mục</Link>
            </li>
            <li className="cursor-pointer whitespace-nowrap">
              <Link to="/lien-he">Liên hệ</Link>
            </li>
          </ul>

          <ul className="flex items-center justify-center space-x-4">
            <li className="w-7">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19">
                <path
                  fill="#fff"
                  d="M16.743,0.6H2.306A1.97,1.97,0,0,0,.337,2.569V17.006a1.97,1.97,0,0,0,1.969,1.969H7.935V12.728H5.351V9.787H7.935V7.546a3.589,3.589,0,0,1,3.842-3.957,15.654,15.654,0,0,1,2.277.2v2.5H12.772a1.47,1.47,0,0,0-1.658,1.589V9.787h2.821l-0.451,2.94h-2.37v6.247h5.629a1.97,1.97,0,0,0,1.969-1.969V2.569A1.97,1.97,0,0,0,16.743.6Z"
                ></path>
              </svg>
            </li>
            <li className="w-9">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 21">
                <path
                  fill="#fff"
                  d="M27.7 4.7c0-2.4-1.8-4.4-4-4.4-3-.1-6-.2-9.2-.2h-1c-3.1 0-6.2.1-9.2.2-2.2 0-4 2-4 4.4C.2 6.6.1 8.5.1 10.5c0 1.9.1 3.8.2 5.8 0 2.4 1.8 4.4 4 4.4 3.1.1 6.4.2 9.6.2 3.3 0 6.5-.1 9.6-.2 2.2 0 4-2 4-4.4.1-1.9.2-3.9.2-5.8.2-1.9.1-3.8 0-5.8zM11.4 15.8V5.2l7.8 5.3-7.8 5.3z"
                ></path>
              </svg>
            </li>
          </ul>
        </div>
        <div className="flex items-center space-x-2">
          <p className="text-center">
            <span className="text-2xl">&#xa9;</span>
            <span> 2024 </span>
            <a
              className="qodef--change-color"
              href="https://qodeinteractive.com/"
              data-type="URL"
              data-id="https://qodeinteractive.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              sieunhanvaanime
            </a>
            <span> All Rights Reserved</span>
          </p>
        </div>
      </div>
    </div>
  );
}
