import { api } from "./config";
const DanhmucService = {
  getDanhmucs() {
    return api.get("/danhmuc");
  },
  deleteDanhmuc(id) {
    return api.token().delete(`/danhmuc/${id}`);
  },
  postDanhmuc(data) {
    return api.token().post(`/danhmuc`, data);
  },
  updateDanhmuc(data) {
    return api.token().patch(`/danhmuc/${data.id}`, data);
  },
};
export default DanhmucService;
