import ProductService from "@/service/product";
import {
  setError,
  setLoading,
  setPaginate,
  setProductPopular,
  setProductAll,
  setProducts,
  postData,
  deleteProductSlice,
  updateData,
  setProductSearch,
  setProductRelate,
} from "./productSlice";

export const getProducts =
  (page, limit, sortBy, minPrice, maxPrice, tagId) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const result = await ProductService.getProducts(
        page,
        limit,
        sortBy,
        minPrice,
        maxPrice,
        tagId
      );
      dispatch(setProducts(result.data));
      dispatch(setPaginate(result.paginate));
    } catch (error) {
      dispatch(setError(error.message));
    } finally {
      dispatch(setLoading(false));
    }
  };
export const getProductPopular = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const result = await ProductService.getProductPopular();
    await dispatch(setProductPopular(result));
  } catch (error) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};
export const getProductRelate = (slug) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const result = await ProductService.getProductRelate(slug);
    await dispatch(setProductRelate(result));
  } catch (error) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};


export const getProductAll = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const result = await ProductService.getProductAll();
    await dispatch(setProductAll(result));
  } catch (error) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const searchProducts = (keywork) => async (dispatch) => {
  try {
    const result = await ProductService.searchProducts(keywork);
    dispatch(setProductSearch(result));
  } catch (error) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const postProduct = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const result = await ProductService.postProduct(data);
    dispatch(postData(result));
  } catch (error) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateProduct = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const result = await ProductService.updateProduct(data);
    dispatch(updateData(result));
  } catch (error) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const deleteProduct = (id) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    ProductService.deleteProduct(id);
    dispatch(deleteProductSlice(id));
  } catch (error) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};
