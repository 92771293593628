import React, { useEffect, useState } from "react";
import { Table } from "antd";
import useCurrency from "@/hook/useCurrency";

export default function DonhangDetail({ donhangchitiets }) {
  const formatCurrency = useCurrency();
  const [totalPrice, setTotalPrice] = useState(0);
  useEffect(() => {
    if (donhangchitiets) {
      const data = donhangchitiets.reduce((total, item) => {
        return total + item.soluong * item.price;
      }, 0);
      setTotalPrice(data);
    }
  }, [donhangchitiets, setTotalPrice]);
  const columns = [
    {
      title: "Sản phẩm",
      render: (text, record) => (
        <p className="cursor-pointer">{record.product.name}</p>
      ),
    },
    {
      title: "Số lượng",
      dataIndex: "phone",
      render: (text, record) => (
        <p className="cursor-pointer">{record.soluong}</p>
      ),
    },
    {
      title: "Đơn giá",
      dataIndex: "email",
      render: (text, record) => (
        <p className="cursor-pointer">{formatCurrency(record.price)}</p>
      ),
    },
  ];
  return (
    <div className="p-5 2xl:mt-16 space-y-4">
      <div className="space-y-4">
        <h2 className="text-2xl font-bold text-black">Đơn hàng chi tiết</h2>
        <span className="text-xl inline-block ">
          Tổng cộng: {formatCurrency(totalPrice)}
        </span>
      </div>

      <Table
        className="h-[80vh] overflow-auto relative"
        columns={columns}
        dataSource={donhangchitiets?.map((e, i) => {
          return {
            ...e,
            key: i,
          };
        })}
      />
    </div>
  );
}
