import { createSlice } from "@reduxjs/toolkit";
import { NotificationManager } from "react-notifications";

const listcart = JSON.parse(localStorage.getItem("listcart"));
const numCart = JSON.parse(localStorage.getItem("num"));
const amountCart = JSON.parse(localStorage.getItem("amount"));
// Initial state
const initialState = {
  list: Array.isArray(listcart) ? listcart : [],
  num: typeof numCart === "number" ? numCart : 0,
  amount: typeof amountCart === "number" ? amountCart : 0,
  loading: false,
  error: null,
};

// Slice
const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addCart: (state, action) => {
      let { list, num } = state;
      const cartNum = action.payload.cartNum ? action.payload.cartNum : 1;
      const item = {
        ...action.payload,
        cartNum: 1,
      };
      state.num = num + cartNum;
      state.amount += item.price * cartNum;
      const index = list.findIndex((e) => e.id === action.payload.id);
      if (index === -1) {
        list.push(item);
      } else {
        list[index].cartNum += cartNum;
      }
      NotificationManager.success("Sản phẩm đã được thêm vào giỏ hàng");

      localStorage.setItem("listcart", JSON.stringify(list));
      localStorage.setItem("amount", JSON.stringify(state.amount));
      localStorage.setItem("num", JSON.stringify(state.num));
    },
    decrease: (state, action) => {
      const { list } = state;
      const index = list.findIndex((e) => e.id === action.payload.id);
      state.num -= 1;
      state.amount -= list[index].price;
      list[index].cartNum -= 1;

      if (list[index].cartNum === 0) {
        list.splice(index, 1);
      }

      localStorage.setItem("listcart", JSON.stringify(list));
      localStorage.setItem("amount", JSON.stringify(state.amount));
      localStorage.setItem("num", JSON.stringify(state.num));
    },
    increase: (state, action) => {
      const { list } = state;
      const index = list.findIndex((e) => e.id === action.payload.id);
      state.num += 1;
      state.amount += action.payload.price;
      list[index].cartNum += 1;

      localStorage.setItem("listcart", JSON.stringify(list));
      localStorage.setItem("amount", JSON.stringify(state.amount));
      localStorage.setItem("num", JSON.stringify(state.num));
    },
    remove: (state, action) => {
      const { list } = state;
      const index = list.findIndex((e) => e.id === action.payload.id);
      const temp = list[index].cartNum;
      state.num -= list[index].cartNum;
      state.amount -= list[index].price * temp;
      list[index].cartNum -= list[index].cartNum;

      if (list[index].cartNum === 0) {
        list.splice(index, 1);
      }
      NotificationManager.success("Sản phẩm đã được xoá khỏi giỏ hàng");
      localStorage.setItem("listcart", JSON.stringify(list));
      localStorage.setItem("amount", JSON.stringify(state.amount));
      localStorage.setItem("num", JSON.stringify(state.num));
    },
    quantity: (state, action) => {
      const { list } = state;
      const index = list.findIndex((e) => e.id === action.payload.id);
      if (index === -1) {
        list.push(action.payload);
        state.num += action.payload.cartNum;
        state.amount += action.payload.cartNum * action.payload.price;
      } else {
        state.num -= list[index].cartNum;
        state.amount -= list[index].cartNum * list[index].price;
        list[index].cartNum = action.payload.cartNum;
        state.num += action.payload.cartNum;
        state.amount += list[index].cartNum * list[index].price;
      }
      localStorage.setItem("listcart", JSON.stringify(list));
      localStorage.setItem("amount", JSON.stringify(state.amount));
      localStorage.setItem("num", JSON.stringify(state.num));
    },
    clearCart: (state) => {
      state.list = [];
      state.num = 0;
      state.amount = 0;
      localStorage.setItem("listcart", JSON.stringify([]));
      localStorage.setItem("amount", JSON.stringify(0));
      localStorage.setItem("num", JSON.stringify(0));
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

// Export actions and reducer
export const {
  addCart,
  decrease,
  increase,
  remove,
  quantity,
  clearCart,
  setLoading,
  setError,
} = cartSlice.actions;

export const selectCartList = (state) => state.cart.list;
export const selectCartNum = (state) => state.cart.num;
export const selectCartAmount = (state) => state.cart.amount;
export const selectLoading = (state) => state.cart.loading;
export const selectError = (state) => state.cart.error;

export default cartSlice.reducer;
