import { configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import userSlice from "@/redux/features/user/userSlice";
import cartSlice from "@/redux/features/cart/cartSlice";
import productSlice from "@/redux/features/product/productSlice";
import danhmucSlice from "@/redux/features/danhmuc/danhmucSlice";
import donhangSlice from "./features/donhang/donhangSlice";
import tagSlice from "./features/tags/tagSlice";
import imageSlice from "./features/image/imageSlice";

const store = configureStore({
  reducer: {
    user: userSlice,
    cart: cartSlice,
    product: productSlice,
    danhmuc: danhmucSlice,
    donhang: donhangSlice,
    tag: tagSlice,
    images: imageSlice
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
  devTools: process.env.NODE_ENV !== "production", // Kích hoạt Redux DevTools trong môi trường phát triển
});

export default store;
