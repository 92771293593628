import { createSlice } from "@reduxjs/toolkit";

// Initial state
const initialState = {
  tags: [],
  loading: false,
  error: null,
};

// Slice
const tagSlice = createSlice({
  name: "tag",
  initialState,
  reducers: {
    setTags: (state, action) => {
      state.tags = action.payload;
      state.loading = false;
      state.error = null;
    },
    postData: (state, action) => {
      state.tags = [action.payload, ...state.tags];
      state.loading = false;
      state.error = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

// Export actions and reducer
export const { setTags, setLoading, setError, postData } =
  tagSlice.actions;

export const selectTags = (state) => state.tag.tags;
export const selectLoading = (state) => state.tag.loading;
export const selectError = (state) => state.tag.error;

export default tagSlice.reducer;
