import { createSlice } from "@reduxjs/toolkit";

// Initial state
const initialState = {
  danhmucs: [],
  loading: false,
  error: null,
};

// Slice
const danhmucSlice = createSlice({
  name: "danhmuc",
  initialState,
  reducers: {
    setDanhmucs: (state, action) => {
      state.danhmucs = action.payload;
      state.loading = false;
      state.error = null;
    },
    postData: (state, action) => {
      state.danhmucs = [action.payload, ...state.danhmucs];
      state.loading = false;
      state.error = null;
    },
    updateDanhmuc: (state, action) => {
      const index = state.danhmucs.findIndex((x) => x.id === action.payload.id);
      state.danhmucs[index] =  action.payload;
      state.loading = false;
      state.error = null;
    },
    deleteDanhmucSlice: (state, action) => {
      state.danhmucs = state.danhmucs.filter((x) => x.id !== action.payload);
      state.loading = false;
      state.error = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

// Export actions and reducer
export const {
  setDanhmucs,
  updateDanhmuc,
  setLoading,
  setError,
  postData,
  deleteDanhmucSlice,
} = danhmucSlice.actions;

export const selectDanhmuc = (state) => state.danhmuc.danhmucs;
export const selectLoading = (state) => state.danhmuc.loading;
export const selectError = (state) => state.danhmuc.error;

export default danhmucSlice.reducer;
