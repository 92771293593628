import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectDanhmuc } from "@/redux/features/danhmuc/danhmucSlice";

export default function Categories() {
  const danhmuc = useSelector(selectDanhmuc);
  return (
    <section className="space-y-10">
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-6">
        {danhmuc.map((e, i) => (
          <Link to={`/danh-muc/${e.slug}`} className=" relative" key={i}>
            <img
              loading="lazy"
              src="https://artorias.qodeinteractive.com/wp-content/uploads/2022/10/vertical-home-img-1.jpg"
              alt=""
            />
            <h3
              className="absolute translate-center md:text-[60px] text-4xl uppercase font-bold text-transparent"
              data-content={e.title.replace(/\s+/g, '')}
            >
              {e.title.replace(/\s+/g, '')}
            </h3>
          </Link>
        ))}
      </div>
    </section>
  );
}
