import React from "react";

export default function Contact() {
  return (
    <div className="mt-20 space-y-20">
      <iframe
        title="Muscle Sentai"
        src="https://my.atlist.com/map/6980a526-838d-48ca-ae83-fa0d084e69a2/?share=true"
        allow="geolocation 'self' https://my.atlist.com"
        width="100%"
        height="400px"
        loading="lazy"
        frameborder="0"
        scrolling="no"
        allowfullscreen
        id="atlist-embed"
      ></iframe>
      <div className="flex lg:space-x-20 lg:flex-row flex-col lg:space-y-0 space-y-10">
        <div className="lg:w-1/2">
          <form className="space-y-6 flex flex-col">
            <input
              type="text"
              name="hoten"
              placeholder="Your Name..."
              className="bg-transparent  border border-gray- p-3 text-gray-400"
            />
            <input
              type="text"
              name="hoten"
              placeholder="Your Email..."
              className="bg-transparent  border border-gray- p-3 text-gray-400"
            />
            <input
              type="text"
              name="hoten"
              placeholder="Your Phone..."
              className="bg-transparent  border border-gray- p-3 text-gray-400"
            />
            <textarea
              placeholder="Your Message..."
              className="bg-transparent  border border-gray- p-3 text-gray-400"
              rows={4}
            ></textarea>
            <button className="button-submit text-nowrap">submit here</button>
          </form>
        </div>
        <div className="lg:w-1/2">
          <div className="flex space-x-10 items-center justify-between relative md:pl-10 pl-2">
            <span className="uppercase text-lg  absolute -left-6 top-12 translate-rotate">
              find us
            </span>
            <div className="space-y-10">
              <h2 className="md:text-3xl text-lg font-bold uppercase">
                Liên hệ với chúng tôi
              </h2>
              <div className="flex">
                <div className="space-y-4">
                  <h2 className="text-lg uppercase font-bold">Cửa hàng: </h2>
                  <p>
                    815/1 đường Nguyễn Tất Thành, thị trấn Long Hải, Huyện Long
                    Điền, Thành phố Bà Rịa- Vũng Tàu{" "}
                  </p>
                </div>
              </div>
              <div className="flex space-x-12">
                <div className="space-y-4">
                  <h2 className="text-lg uppercase font-bold">
                    Số điện thoại:{" "}
                  </h2>
                  <p>0937123123</p>
                </div>
                <div className="space-y-4">
                  <h2 className="text-lg uppercase font-bold">Email: </h2>
                  <p>ticuong436@gmail.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
