import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { selectUser } from "../../redux/features/user/userSlice";

export default function Sidebar() {
  const user = useSelector(selectUser);

  return (
    <div className="bg-black w-96 text-white h-full space-y-6 border-r border-black">
      <div className="flex flex-col items-center justify-center 2xl:mt-28 mt-16 space-y-4">
        <img src={user.photoURL} className="rounded-full" alt="" />
        <h2 className="uppercase text-xl font-bold">{user.displayName}</h2>
        <h2>{user.email}</h2>
      </div>
      <ul className="text-xl menu-admin">
        <li>
          <NavLink
            className="cursor-pointer space-x-4 flex items-center hover:bg-white hover:text-black p-5"
            to="/admin/report"
          >
            <span className="material-symbols-outlined text-3xl">
              shopping_cart
            </span>
            <span className="text-xl">Báo cáo</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            className="cursor-pointer space-x-4 flex items-center hover:bg-white hover:text-black p-5"
            to="/admin/order"
          >
            <span className="material-symbols-outlined text-3xl">
              shopping_cart
            </span>
            <span className="text-xl"> Đơn hàng</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            className="cursor-pointer space-x-4 flex items-center hover:bg-white hover:text-black p-5"
            to="/admin/product"
          >
            <span className="material-symbols-outlined text-3xl">
              package_2
            </span>
            <span className="text-xl"> Sản phẩm</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            className="cursor-pointer space-x-4 flex items-center hover:bg-white hover:text-black p-5"
            to="/admin/danhmuc"
          >
            <span className="material-symbols-outlined text-3xl">category</span>
            <span className="text-xl"> Danh mục</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            className="cursor-pointer space-x-4 flex items-center hover:bg-white hover:text-black p-5"
            to="/admin/tags"
          >
            <span className="material-symbols-outlined text-3xl">style</span>
            <span className="text-xl">Tags</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            className="cursor-pointer space-x-4 flex items-center hover:bg-white hover:text-black p-5"
            to="/admin/email"
          >
            <span className="material-symbols-outlined text-3xl">mail</span>
            <span className="text-xl">Email</span>
          </NavLink>
        </li>
      </ul>
    </div>
  );
}
