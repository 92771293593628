import { format, parseISO } from 'date-fns';
import { useMemo } from 'react';

const useFormattedDate = ({ dateString, formatString = 'dd/MM/yyyy HH:mm:ss' }) => {
  return useMemo(() => {
    try {
      const date = parseISO(dateString);
      return format(date, formatString);
    } catch (error) {
      console.error('Invalid date string', error);
      return '';
    }
  }, [dateString, formatString]);
};

export default useFormattedDate;