import React from "react";

const Pagination = ({ paginate, nextPage, selectPage, prevPage }) => {
  return (
    <div className="flex items-center justify-center  space-x-6">
      <span
        onClick={prevPage}
        className={`flex items-center space-x-3 text-gray-400 hover:text-white cursor-pointer group ${
          paginate.currentPage === 1 && "cursor-not-allowed"
        }`}
      >
        <svg
          className="w-5 h-5 fill-gray-400 group-hover:fill-white"
          viewBox="0 0 39 50"
        >
          <path d="M38.9 50 0 25.1 38.9.3V50zm-24-24.9L31 35.4V14.9L14.9 25.1z"></path>
        </svg>
      </span>
      {[...Array(paginate.totalPages)].map((e, i) => (
        <span
          onClick={() => selectPage(i + 1)}
          className={` text-xl  ${
            paginate.currentPage === i + 1
              ? "text-white"
              : "hover:text-white cursor-pointer text-gray-400"
          }`}
          key={i}
        >
          {i + 1}
        </span>
      ))}

      <span
        onClick={nextPage}
        className={`flex items-center space-x-3 text-gray-400 hover:text-white cursor-pointer group ${
          paginate.currentPage === paginate.totalPages && "cursor-not-allowed"
        }`}
      >
        <svg
          className="w-5 h-5 fill-gray-400 group-hover:fill-white"
          viewBox="0 0 39 50"
        >
          <path d="m0 .3 38.9 24.9L0 50V.3zm24.1 24.8L8 14.9v20.5l16.1-10.3z"></path>
        </svg>
      </span>
    </div>
  );
};

export default Pagination;
