import { api } from "./config";
const ImagesService = {
  getImages(danhmuc) {
    return api.token().get(`/uploads?danhmuc=${danhmuc}`);
  },
  deleteImage(danhmuc, data) {
    return api.token().delete(`/uploads/${danhmuc}`, data);
  },
  postImage(file, danhmuc) {
    const formData = new FormData();
    formData.append("file", file); // Gửi file trong formData

    return fetch(`http://localhost:3333/api/uploads?danhmuc=${danhmuc}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Thêm header Authorization với token từ localStorage
      },
      body: formData, // Gửi formData chứa file
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Upload failed.");
        }
        return response.json(); // Trả về response dưới dạng JSON nếu thành công
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
        throw error; // Ném lỗi nếu có lỗi xảy ra trong quá trình gửi yêu cầu
      });
  },
};
export default ImagesService;
