import React, { useEffect, useRef, useState } from "react";
import muscel from "../../assets/images/muscel.png";
import useCurrency from "../../hook/useCurrency";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

export default function Slider() {
  const formatCurrency = useCurrency();
  const swiperRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(null);
  useEffect(() => {
    setActiveIndex(0);
  }, []);
  const slides = [
    { src: muscel, title: "Gao Muscel", price: "$100" },
    { src: muscel, title: "Gao Muscel", price: "$100" },
    { src: muscel, title: "Gao Muscel", price: "$100" },
    // Add more slides as needed
  ];

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      const swiperInstance = swiperRef.current.swiper;
      const isFirstSlide = swiperInstance.isBeginning; // Kiểm tra xem có phải slide đầu tiên không

      if (isFirstSlide) {
        swiperInstance.slideTo(swiperInstance.slides.length - 1); // Chuyển đến slide cuối cùng
      } else {
        swiperInstance.slidePrev(); // Chuyển đến slide trước đó
      }

      setActiveIndex(null); // Thiết lập active index (nếu cần thiết)
    }
  };

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      const swiperInstance = swiperRef.current.swiper;
      const isLastSlide = swiperInstance.isEnd; // Kiểm tra xem có phải slide cuối cùng không

      if (isLastSlide) {
        swiperInstance.slideTo(0); // Chuyển đến slide đầu tiên
      } else {
        swiperInstance.slideNext(); // Chuyển đến slide tiếp theo
      }
    }
    setActiveIndex(null); //
  };
  const handleSlideChange = (swiper) => {
    setActiveIndex(null);
    setTimeout(() => {
      setActiveIndex(swiper.activeIndex);
    }, 500);
  };

  return (
    <div className="relative">
      <Swiper
        ref={swiperRef}
        className="mySwiper"
        spaceBetween={50}
        slidesPerView={1}
        speed={500}
        loop={false}
        modules={[Autoplay, Navigation]}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        onSlideChange={handleSlideChange}
      >
        {" "}
        {slides.map((e, i) => (
          <SwiperSlide key={i}>
            <section className="space-y-10 lg:mt-0 mt-20">
              <div className="bg-gradient">
                <img
                  src={e.src}
                  className="md:h-[600px] h-[350px]  mx-auto object-contain"
                  loading="lazy"
                  alt=""
                />
              </div>
              <div
                className={`flex items-center justify-between slider ${
                  i === activeIndex ? "active" : ""
                }`}
              >
                <div className="space-y-4">
                  <h2 className="md:text-3xl text-xl uppercase font-bold">
                    Gao Muscle
                  </h2>
                  <span className="md:text-lg !block w-full price-product">
                    {formatCurrency(2000000)}
                  </span>
                </div>
              </div>
            </section>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="flex items-center space-x-4 absolute bottom-4 right-4 z-10">
        <svg
          className="cursor-pointer lg:w-10  w-6"
          viewBox="0 0 39 50"
          onClick={handlePrev}
        >
          <path
            d="M38.9 50 0 25.1 38.9.3V50zm-24-24.9L31 35.4V14.9L14.9 25.1z"
            fill="#fff"
          ></path>
        </svg>
        <svg
          className="cursor-pointer lg:w-10  w-6"
          viewBox="0 0 39 50"
          onClick={handleNext}
        >
          <path
            d="m0 .3 38.9 24.9L0 50V.3zm24.1 24.8L8 14.9v20.5l16.1-10.3z"
            fill="#fff"
          >
            {" "}
          </path>
        </svg>
      </div>
    </div>
  );
}
