import { useState, useEffect } from "react";

const useSlug = (initialValue = "") => {
  const [name, setName] = useState(initialValue);
  const [slug, setSlug] = useState("");

  useEffect(() => {
    const generateSlug = name
      .toLowerCase()
      .trim()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
    setSlug(generateSlug);
  }, [name]);

  return [name, setName, slug, setSlug];
};

export default useSlug;
