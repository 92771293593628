import { createSlice } from "@reduxjs/toolkit";

// Initial state
const initialState = {
  images: [],
  loading: false,
  error: null,
};

// Slice
const imageSlice = createSlice({
  name: "images",
  initialState,
  reducers: {
    setImages: (state, action) => {
      state.images = action.payload;
      state.loading = false;
      state.error = null;
    },
    postData: (state, action) => {
      state.images = [action.payload, ...state.images];
      state.loading = false;
      state.error = null;
    },
    updateImage: (state, action) => {
      const index = state.images.filter((x) => x.id === action.payload.id);
      state.images[index] = action.payload;
      state.loading = false;
      state.error = null;
    },
    deleteImageSlice: (state, action) => {
      state.images = state.images.filter((x) => x !== action.payload);
      state.loading = false;
      state.error = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

// Export actions and reducer
export const {
  setImages,
  updateImage,
  setLoading,
  setError,
  postData,
  deleteImageSlice,
} = imageSlice.actions;

export const selectImage = (state) => state.images.images;
export const selectLoading = (state) => state.images.loading;
export const selectError = (state) => state.images.error;

export default imageSlice.reducer;
