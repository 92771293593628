import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
  onAuthStateChanged,
  fetchSignInMethodsForEmail,
  EmailAuthProvider,
  linkWithCredential,
  signInWithCredential,
  linkWithPopup,
} from "firebase/auth";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDV1SuqDF6E2bsiIjbbjUttMz-kwy0lDVk",
  authDomain: "gaoranger-74b5c.firebaseapp.com",
  projectId: "gaoranger-74b5c",
  storageBucket: "gaoranger-74b5c.appspot.com",
  messagingSenderId: "38706481383",
  appId: "1:38706481383:web:e55a0f1956551bf6c8fa42",
  measurementId: "G-0LMBK2P5HV",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore();

const provider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

export {
  auth,
  provider,
  db,
  doc,
  setDoc,
  getDoc,
  signInWithPopup,
  facebookProvider,
  onAuthStateChanged,
  EmailAuthProvider,
  linkWithCredential,
  fetchSignInMethodsForEmail,
  signInWithCredential,
  linkWithPopup,
};
