import { createSlice } from "@reduxjs/toolkit";

// Initial state
const initialState = {
  products: [],
  productSearch: [],
  productRelate: [],
  productPopular: [],
  productAll: [],
  paginate: null,
  productNew: [],
  loading: false,
  error: null,
};

// Slice
const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload;
      state.loading = false;
      state.error = null;
    },
    setProductSearch: (state, action) => {
      state.productSearch = action.payload;
      state.loading = false;
      state.error = null;
    },
    setProductRelate: (state, action) => {
      state.productRelate = action.payload;
      state.loading = false;
      state.error = null;
    },
    setProductAll: (state, action) => {
      state.productAll = action.payload;
      state.loading = false;
      state.error = null;
    },
    postData: (state, action) => {
      state.productAll = [action.payload, ...state.productAll];
      state.loading = false;
      state.error = null;
    },
    updateData: (state, action) => {
      const index = state.productAll.findIndex(
        (x) => x.id === action.payload.id
      );
      state.productAll[index] = action.payload;
      state.loading = false;
      state.error = null;
    },
    deleteProductSlice: (state, action) => {
      state.productAll = state.productAll.filter(
        (x) => x.id !== action.payload.id
      );
      state.loading = false;
      state.error = null;
    },
    setProductPopular: (state, action) => {
      state.productPopular = action.payload;
      state.loading = false;
      state.error = null;
    },
    setPaginate: (state, action) => {
      state.paginate = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

// Export actions and reducer
export const {
  setProducts,
  setProductRelate,
  setProductPopular,
  setProductAll,
  setLoading,
  setError,
  setPaginate,
  postData,
  updateData,
  deleteProductSlice,
  setProductSearch,
} = productSlice.actions;

export const selectProducts = (state) => state.product.products;
export const selectProductRelate = (state) => state.product.productRelate;
export const selectProductSearch = (state) => state.product.productSearch;
export const selectProductNew = (state) => state.product.productNew;
export const selectProductPopular = (state) => state.product.productPopular;
export const selectProductAll = (state) => state.product.productAll;
export const selectPaginate = (state) => state.product.paginate;
export const selectLoading = (state) => state.product.loading;
export const selectError = (state) => state.product.error;

export default productSlice.reducer;
