import TagsService from "@/service/tags";
import { setError, setLoading, setTags, postData } from "./tagSlice";

export const getTags = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const result = await TagsService.getTags();
    await dispatch(setTags(result));
  } catch (error) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const postTag = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const result = await TagsService.postTag(data);
    dispatch(postData(result));
  } catch (error) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

// export const deleteTag = (id) => async (dispatch) => {
//   dispatch(setLoading(true));
//   try {
//     TagsService.de(id);
//     dispatch(delete(id));
//   } catch (error) {
//     dispatch(setError(error.message));
//   } finally {
//     dispatch(setLoading(false));
//   }
// };
