import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useCurrency from "@/hook/useCurrency";
import { handleRemove } from "@/redux/features/cart/cartAction";
import {
  selectCartAmount,
  selectCartList,
  selectCartNum,
} from "@/redux/features/cart/cartSlice";
import { getDanhmucs } from "@/redux/features/danhmuc/danhmucAction";
import { selectDanhmuc } from "@/redux/features/danhmuc/danhmucSlice";
import { handleSignOut } from "@/redux/features/user/userAction";
import { selectUser } from "@/redux/features/user/userSlice";
import { Badge } from "antd";
import { searchProducts } from "@/redux/features/product/productAction";
import { selectProductSearch } from "@/redux/features/product/productSlice";
export default function Header({ handleCollapsed }) {
  const dispatch = useDispatch();
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const formatCurrency = useCurrency();
  const userSelector = useSelector(selectUser);
  const danhmucSelector = useSelector(selectDanhmuc);
  const productSelector = useSelector(selectProductSearch);
  const cartNum = useSelector(selectCartNum);
  const amount = useSelector(selectCartAmount);
  const cartList = useSelector(selectCartList);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isSelectDanhmuc, setIsSelectDanhmuc] = useState(false);
  const [productSearch, setProductSearch] = useState([]);
  useEffect(() => {
    dispatch(getDanhmucs());
  }, [dispatch]);
  useEffect(() => {
    setProductSearch(productSelector);
  }, [productSelector]);
  const handleSearch = (event) => {
    const { value } = event.target;
    // Clear previous timeout if exists
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    // Set new timeout
    const timeout = setTimeout(() => {
      dispatch(searchProducts(value));
    }, 500); // Adjust debounce delay as needed

    setDebounceTimeout(timeout);
  };
  return (
    <>
      <header className="fixed left-0 top-0 w-full h-32 z-[20] hidden lg:inline-block bg-black border-b border-b-gray-300">
        <div className="container flex items-center justify-between  h-full">
          <div className="w-56">
            <Link to="/">
              <img
                src="https://artorias.qodeinteractive.com/wp-content/uploads/2022/11/footer-logo-img.png"
                alt=""
              />
            </Link>
          </div>
          <div className="w-2/3 flex items-center space-x-10 justify-end">
            <nav className=" z-10">
              <ul className="space-x-6 flex list-menu items-center relative">
                <li>
                  <Link
                    to="/"
                    className="font-bold text-basse uppercase"
                    href=""
                  >
                    Trang chủ
                  </Link>
                </li>
                <li className="font-bold text-basse uppercase">
                  <Link to="/ve-chung-toi">Về chúng tôi</Link>
                </li>
                <li className="menu-hover">
                  <h2 className="font-bold text-basse uppercase relative">
                    Danh mục
                  </h2>
                  <div className="danhmuc-list pt-6 absolute z-10">
                    <ul className="space-y-4 ml-2 border border-gray-300 bg-black pl-6 pr-20 py-6 ">
                      {danhmucSelector.map((e, i) => (
                        <li
                          className="text-gray-400 text-basse cursor-pointer"
                          key={i}
                        >
                          <Link to={`/danh-muc/${e.slug}`}>{e.title}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </li>
                <li>
                  <Link
                    className="font-bold text-basse uppercase"
                    to="/lien-he"
                  >
                    Liên hệ
                  </Link>
                </li>
                <li className="cart-hover">
                  <Badge color="#1a1a1d" count={cartNum}>
                    <span className="material-symbols-outlined text-white text-3xl">
                      shopping_cart
                    </span>
                  </Badge>

                  <div className="cart-list pt-6 absolute z-10">
                    <div className="ml-2 border border-gray-300  p-6 bg-black space-y-6">
                      {cartList.length > 0 ? (
                        <>
                          <ul className="space-y-4">
                            {cartList.map((e, i) => (
                              <li
                                className=" text-basse  flex justify-between space-x-4"
                                key={i}
                              >
                                <img
                                  src={e.image}
                                  className="w-28 border p-2 border-gray-300"
                                  alt=""
                                />
                                <div className="space-y-4">
                                  <h2 className="uppercase font-bold text-white text-wrap">
                                    {e.name}
                                  </h2>
                                  <span>
                                    {e.cartNum} x {formatCurrency(e.price)}
                                  </span>
                                </div>
                                <span
                                  className="cursor-pointer"
                                  onClick={() => {
                                    dispatch(handleRemove(e));
                                  }}
                                >
                                  x
                                </span>
                              </li>
                            ))}
                          </ul>
                          <div className="flex items-center justify-between">
                            <span className="inline-block text-gray-400">
                              Tổng
                            </span>
                            <span className="inline-block text-gray-400">
                              {formatCurrency(amount)}
                            </span>
                          </div>
                          <Link
                            to="/gio-hang"
                            className="button-submit text-nowrap !w-full "
                          >
                            Giỏ hàng & Thanh toán
                          </Link>
                        </>
                      ) : (
                        <span className="text-gray-400">
                          Không có sản phẩm trong giỏ hàng
                        </span>
                      )}
                    </div>
                  </div>
                </li>

                <li>
                  <span
                    className={`material-symbols-outlined text-3xl cursor-pointer relative ${
                      isSearch ? "z-1" : "z-10"
                    }`}
                    onClick={() => {
                      setIsSearch(!isSearch);
                    }}
                  >
                    search
                  </span>
                  <div
                    className={`absolute right-0 top-1/2 translate-y-[-50%]  z-5 bg-black header-search ${
                      isSearch && "header-search-active"
                    }`}
                  >
                    <input
                      onChange={handleSearch}
                      className="email-input w-full !px-4 !py-6 bg-black"
                      placeholder="Tìm kiếm"
                    />
                    <span
                      className="material-symbols-outlined cursor-pointer right-2 absolute top-1/2 translate-y-[-50%] text-3xl "
                      onClick={() => {
                        setIsSearch(false);
                        setProductSearch([]);
                      }}
                    >
                      close
                    </span>
                  </div>
                </li>
                {userSelector ? (
                  <li className="login-hover">
                    <img
                      src={userSelector.photoURL}
                      className="rounded-full h-10 w-10"
                      alt=""
                    />
                    <div className="login-list pt-6 absolute z-10">
                      <ul className="space-y-4 ml-2 border border-gray-300 bg-black w-60 px-6 py-6 ">
                        <li className="text-gray-400 text-basse after:!content-none cursor-pointer flex space-x-4 items-center border-b border-gray-300  pb-3">
                          <img
                            src={userSelector.photoURL}
                            className="rounded-full h-12 w-12"
                            alt=""
                          />
                          <span>{userSelector.displayName}</span>
                        </li>
                        {userSelector.role === "admin" && (
                          <li className="text-gray-400 text-basse cursor-pointer ml-[56px]">
                            <Link to="/admin/report">Admin</Link>
                          </li>
                        )}
                        <li
                          className="text-gray-400 text-basse cursor-pointer ml-[56px]"
                          onClick={() => {
                            dispatch(handleSignOut());
                          }}
                        >
                          Đăng xuất
                        </li>
                      </ul>
                    </div>
                  </li>
                ) : (
                  <li className="font-light text-basse uppercase">
                    <Link to="/dang-nhap">Đăng nhập</Link>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        </div>
      </header>
      <header
        className={`lg:hidden block fixed w-full overflow-hidden top-0 bg-black header-mobile border-b border-b-gray-300 z-[20] ${
          isOpenMenu ? "header-active" : "h-24"
        } ${isSelectDanhmuc && isOpenMenu && "header-danhmuc-active"}`}
      >
        <div className="flex items-center justify-between px-6 py-7 z-10 relative bg-black">
          <span
            onClick={() => {
              setIsOpenMenu(!isOpenMenu);
            }}
          >
            {isOpenMenu ? (
              <svg
                className="qodef-svg--close"
                width="47"
                height="36"
                viewBox="0 0 47 36"
              >
                <path
                  fill="#fff"
                  d="M46.9 29.3 30.4 17.9 46.9 6.5 42.5.1l-19 13L4.4 0 0 6.4l16.7 11.5L-.1 29.4l4.4 6.3 19.2-13.1 19.1 13.1z"
                ></path>
              </svg>
            ) : (
              <svg
                className="qodef-svg--menu cursor-pointer"
                width="47.25"
                height="35"
                viewBox="0 0 47.25 35"
              >
                <path
                  fill="#fff"
                  d="M1.25 35a1.176 1.176 0 0 1-.9-.375A1.242 1.242 0 0 1 0 33.75V1.25a1.218 1.218 0 0 1 .35-.9 1.218 1.218 0 0 1 .9-.35H24.6a1.218 1.218 0 0 1 .9.35 1.218 1.218 0 0 1 .35.9v4.7a1.218 1.218 0 0 1-.35.9 1.218 1.218 0 0 1-.9.35H8.65v6.75H23.5a1.176 1.176 0 0 1 .9.375 1.3 1.3 0 0 1 .35.925v4.35a1.242 1.242 0 0 1-.35.875 1.176 1.176 0 0 1-.9.375H8.65v6.95H25a1.218 1.218 0 0 1 .9.35 1.218 1.218 0 0 1 .35.9v4.7a1.242 1.242 0 0 1-.35.875A1.176 1.176 0 0 1 25 35Z"
                ></path>
                <path
                  fill="#fff"
                  d="M46 35a1.176 1.176 0 0 0 .9-.375 1.242 1.242 0 0 0 .35-.875V1.25a1.218 1.218 0 0 0-.35-.9A1.218 1.218 0 0 0 46 0H22.65a1.218 1.218 0 0 0-.9.35 1.218 1.218 0 0 0-.35.9v4.7a1.218 1.218 0 0 0 .35.9 1.218 1.218 0 0 0 .9.35H38.6v6.75H23.75a1.176 1.176 0 0 0-.9.375 1.3 1.3 0 0 0-.35.925v4.35a1.242 1.242 0 0 0 .35.875 1.176 1.176 0 0 0 .9.375H38.6v6.95H22.25a1.218 1.218 0 0 0-.9.35 1.218 1.218 0 0 0-.35.9v4.7a1.242 1.242 0 0 0 .35.875 1.176 1.176 0 0 0 .9.375Z"
                ></path>
              </svg>
            )}
          </span>

          <Link to="/" className="translate-center absolute z-9">
            {" "}
            <img
              src="https://artorias.qodeinteractive.com/wp-content/themes/artorias/assets/img/logo.svg"
              alt=""
            />
          </Link>
          <div className="flex items-center space-x-4">
            <Badge color="#1a1a1d" count={cartNum} onClick={handleCollapsed}>
              <span className="material-symbols-outlined text-white text-3xl">
                shopping_cart
              </span>
            </Badge>

            <span
              className={`material-symbols-outlined text-3xl ${
                isSearch ? "z-1" : "z-10"
              }`}
              onClick={() => {
                setIsSearch(!isSearch);
              }}
            >
              search
            </span>
            <div
              className={`absolute right-0 p-6 z-4 bg-black header-search ${
                isSearch && "header-search-active "
              }`}
            >
              <input
                onChange={handleSearch}
                className="email-input w-full bg-black"
                placeholder="Tìm kiếm"
              />
              <span
                className="material-symbols-outlined right-6 absolute z-2 top-1/2 translate-y-[-50%] text-3xl "
                onClick={() => {
                  setIsSearch(false);
                  setProductSearch([]);
                }}
              >
                close
              </span>
            </div>
            {!userSelector ? (
              <Link to="/dang-nhap" className="z-6 relative">
                <span className="material-symbols-outlined text-3xl">
                  account_circle
                </span>
              </Link>
            ) : (
              <img
                src={userSelector.photoURL}
                className="rounded-full h-10 w-10"
                alt=""
              />
            )}
          </div>
        </div>
        <nav className={` w-full bg-[#1A1A1D] z-5 overflow-hidden `}>
          <ul className="space-y-6 p-6">
            <li
              className="uppercase font-bold"
              onClick={() => {
                setIsOpenMenu(false);
              }}
            >
              <Link to="/">Trang chủ</Link>
            </li>
            <li
              className="uppercase font-bold"
              onClick={() => {
                setIsOpenMenu(false);
              }}
            >
              <Link to="/ve-chung-toi">Về chúng tôi</Link>
            </li>
            <li
              className={` space-y-4 relative overflow-hidden nav-danhmuc ${
                isSelectDanhmuc && "nav-danhmuc-active"
              }`}
            >
              <div
                className="flex items-center justify-between "
                onClick={() => {
                  setIsSelectDanhmuc(!isSelectDanhmuc);
                }}
              >
                <span className="uppercase font-bold ">Danh mục</span>
                <span>
                  <svg
                    className={`${isSelectDanhmuc && "vector-rotate"} vector`}
                    width="20"
                    height="20"
                    viewBox="0 0 39 50"
                  >
                    <path
                      fill="#fff"
                      d="m0 .3 38.9 24.9L0 50V.3zm24.1 24.8L8 14.9v20.5l16.1-10.3z"
                    ></path>
                  </svg>
                </span>
              </div>

              <ul className={`space-y-4 font-light text-gray-400 ml-4 `}>
                {danhmucSelector.map((e, i) => (
                  <li
                    key={i}
                    onClick={() => {
                      setIsOpenMenu(false);
                    }}
                  >
                    <Link to={`/danh-muc/${e.slug}`} key={i}>
                      {e.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
            <li
              className="uppercase font-bold"
              onClick={() => {
                setIsOpenMenu(false);
              }}
            >
              <Link to="/lien-he">Liên hệ</Link>
            </li>
          </ul>
        </nav>
        <ul
          className={`fixed w-full search-product bg-black z-1 space-y-4  overflow-auto ${
            productSearch.length > 0 && isSearch && "search-product-active"
          }`}
        >
          {productSearch.map((e, i) => (
            <li className="flex space-x-4" key={i}>
              <img
                className="w-20 border border-gray-400"
                src={e.image}
                alt=""
              />
              <Link
                className="space-y-4"
                to={`san-pham/${e.slug}`}
                onClick={() => {
                  setIsSearch(false);
                  setProductSearch([]);
                }}
              >
                <h2 className="text-lg font-bold uppercase">{e.name}</h2>
                <span className="inline-block">{formatCurrency(e.price)}</span>
              </Link>
            </li>
          ))}
        </ul>
      </header>
    </>
  );
}
