import { setUser, clearUser } from "./userSlice";
import {
  auth,
  provider,
  signInWithPopup,
  facebookProvider,
  // EmailAuthProvider,
  // fetchSignInMethodsForEmail,
  // linkWithCredential,
  // signInWithCredential,
} from "@/service/firebase";
import { signOut } from "firebase/auth";

export const handleSignInGoogle = () => async (dispatch) => {
  const result = await signInWithPopup(auth, provider);
  dispatch(
    setUser({
      displayName: result.user.displayName,
      email: result.user.email,
      photoURL: result.user.photoURL,
    })
  );
};

export const handleSignInFacebook = () => async (dispatch) => {
  const result = await signInWithPopup(auth, facebookProvider);
  dispatch(
    setUser({
      displayName: result.user.displayName,
      email: result.user.email,
      photoURL: result.user.photoURL,
    })
  );
};

export const handleSignOut = () => async (dispatch) => {
  try {
    await signOut(auth);
    dispatch(clearUser());
  } catch (error) {
    console.error("Error signing out:", error.message);
  }
};

// const handleAuthError = async (error) => {
//   if (error.code === "auth/account-exists-with-different-credential") {
//     try {
//       const email = error.customData.email;
//       const pendingCred = error.credential;
//       const methods = await fetchSignInMethodsForEmail(
//         auth,
//         "ticuong436@gmail.com"
//       );
//       if (methods.length === 0) {
//         console.error("No email/password account found for this email.");
//         return;
//       }

//       if (methods.includes("password")) {
//         const password = prompt(
//           "Please enter your password to link accounts:"
//         );
//         const credential = EmailAuthProvider.credential(email, password);

//         const userCredential = await signInWithCredential(auth, credential);
//         await linkWithCredential(userCredential.user, pendingCred);
//         setUser(userCredential.user);
//         console.log("Account successfully linked:", userCredential.user);
//       } else {
//         console.error(
//           "This email is associated with a different sign-in method."
//         );
//       }
//     } catch (linkError) {
//       console.error("Error linking accounts:", linkError.message);
//     }
//   } else {
//     console.error("Unhandled error:", error.message);
//   }
// };
