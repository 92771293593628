import "./App.css";
import Homepage from "./page/homepage";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Shop from "./page/shop";
import ProductDetail from "./page/productDetail";
import { useEffect } from "react";
import Checkout from "./page/checkout";
import Cart from "./page/cart";
import Error from "./page/404";
import Contact from "./page/contact";
import About from "./page/about";
import Login from "./page/login";
import { Provider } from "react-redux";
import store from "./redux";
import PrivateRoute from "./componnents/privateRoute";
import Product from "./admin/product";
import { onAuthStateChanged } from "firebase/auth";
import { auth, doc, db, getDoc } from "@/service/firebase";
import { setUser } from "@/redux/features/user/userSlice";
import { useDispatch } from "react-redux";
import Layout from "./layout/clientLayout";
import AdminLayout from "./layout/adminLayout";
import Danhmuc from "./admin/danhmuc";
import Donhang from "./admin/donhang";
import Email from "./admin/email";
import Tags from "./admin/Tags";
import Report from "./admin/report";
const ScrollToTop = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        let dataUser = {
          displayName: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
        };
        localStorage.setItem("accessToken", user.accessToken);
        const userDocRef = doc(db, "admin", user.uid);
        try {
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            dataUser = { ...dataUser, role: userDocSnap.data()?.role };
          } else {
            console.log("Document does not exist");
          }
        } catch (error) {}
        await dispatch(setUser(dataUser));
      } else {
        // User is signed out
        console.log("User is signed out");
        // You might want to dispatch a clear user action if needed
      }
    });

    return () => unsubscribe(); // Unsubscribe when component unmounts
  }, [dispatch]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default function App() {
  return (
    <Provider store={store}>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Homepage />} />
            <Route path="danh-muc/:slug" element={<Shop />} />
            <Route path="san-pham" element={<Navigate to="/danh-muc/gaoranger" />} />
            <Route path="gio-hang" element={<Cart />} />
            <Route path="thanh-toan" element={<Checkout />} />
            <Route path="lien-he" element={<Contact />} />
            <Route path="ve-chung-toi" element={<About />} />
            <Route path="dang-nhap" element={<Login />} />
            <Route path="san-pham/:slug" element={<ProductDetail />} />
          </Route>
          <Route
            path="/admin"
            element={
              <PrivateRoute>
                <AdminLayout />
              </PrivateRoute>
            }
          >
            <Route path="report" element={<Report />} />
            <Route path="product" element={<Product />} />
            <Route path="danhmuc" element={<Danhmuc />} />
            <Route path="order" element={<Donhang />} />
            <Route path="email" element={<Email />} />
            <Route path="tags" element={<Tags />} />

            {/* Thêm các route admin khác tại đây */}
          </Route>
          <Route path="*" element={<Error />} />
        </Routes>
      </Router>
    </Provider>
  );
}
