import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Sider from "antd/es/layout/Sider";
import useCurrency from "@/hook/useCurrency";
import {
  selectCartAmount,
  selectCartList,
} from "@/redux/features/cart/cartSlice";
import { Link } from "react-router-dom";
import { handleRemove } from "@/redux/features/cart/cartAction";
import { selectCartNum } from "../redux/features/cart/cartSlice";

export default function CartSideNav({ collapsed, handleCollapsed }) {
  const amount = useSelector(selectCartAmount);
  const cartList = useSelector(selectCartList);
  const cartNum = useSelector(selectCartNum);
  const formatCurrency = useCurrency();
  const dispatch = useDispatch();
  return (
    <>
      <Sider
        className="border-l border-l-white"
        trigger={null}
        collapsible
        width={collapsed ? 0 : "80vw"}
        collapsed={collapsed}
        style={{
          top: 0,
          position: "fixed",
          right: collapsed ? -200 : 0,
          height: "100vh",
          zIndex: 100,
          background: "#1a1a1d",
        }}
      >
        <div className="w-full h-full space-y-10 relative px-2">
          <div className="flex items-center justify-between mt-4">
            <span
              className="material-symbols-outlined text-3xl font-bold"
              onClick={handleCollapsed}
            >
              close
            </span>
            <span className="text-lg uppercase font-bold">
              Giỏ hàng ({cartNum})
            </span>
          </div>

          <ul className="w-full bg-black z-1 space-y-4 min-h-96  max-h-[500px] border border-gray-400 p-2 overflow-auto">
            {cartList.length === 0 ? (
              <li className="w-full h-full text-center space-y-10 pt-20">
                <h2 className="text-lg">Giỏ hàng của bạn đang trống</h2>
                <Link
                  to="danh-muc/gaoranger"
                  className="button-submit text-nowrap !w-full"
                  onClick={handleCollapsed}
                >
                  Khám phá ngay
                </Link>
              </li>
            ) : (
              cartList.map((e, i) => (
                <li className="flex space-x-4 relative" key={i}>
                  <img
                    className="w-20 border border-gray-400"
                    src={e.image}
                    alt=""
                  />
                  <div className="space-y-4">
                    <h2 className=" text-base font-bold uppercase">{e.name}</h2>
                    <span className="inline-block">
                      {e.cartNum} x {formatCurrency(e.price)}
                    </span>
                  </div>
                  <span
                    className="material-symbols-outlined absolute left-0 !ml-0 top-0 text-xl font-bold "
                    onClick={() => {
                      dispatch(handleRemove(e));
                    }}
                  >
                    close
                  </span>
                </li>
              ))
            )}
          </ul>
          {cartList.length > 0 && (
            <>
              <div className="border-y border-y-gray-300 py-4">
                <span>Tổng cộng: </span>
                <span className="inline-block"> {formatCurrency(amount)}</span>
              </div>
              <div className="space-y-4 ">
                <Link
                  to="gio-hang"
                  className="button-submit text-nowrap !w-full"
                  onClick={handleCollapsed}
                >
                  Giỏ hàng
                </Link>
                <Link
                  to="thanh-toan"
                  className="button-submit text-nowrap !w-full"
                  onClick={handleCollapsed}
                >
                  Tiến hành Thanh toán
                </Link>
              </div>
            </>
          )}
        </div>
      </Sider>
      {!collapsed && (
        <div
          className="w-full h-full fixed left-0 top-0 bg-black opacity-60 z-[99] cursor-pointer"
          onClick={handleCollapsed}
        ></div>
      )}
    </>
  );
}
