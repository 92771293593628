import React, { useEffect, useState } from "react";
import Loading from "@/componnents/loading";
import Footer from "./footer";
import Header from "./header";
import { NotificationContainer } from "react-notifications";
import { Outlet } from "react-router-dom";
import CartSideNav from "../../componnents/cartSideNav";

export default function Layout() {
  const [collapsed, setCollapsed] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleLoad = () => {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    };

    if (document.readyState === "complete") {
      handleLoad();
    } else {
      window.addEventListener("load", handleLoad);
      return () => {
        window.removeEventListener("load", handleLoad);
      };
    }
  }, []);

  const handleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="relative overflow-hidden lg:mt-20">
          <Header handleCollapsed={handleCollapsed} />
          <CartSideNav
            collapsed={collapsed}
            handleCollapsed={handleCollapsed}
          />
          <div className="w-full h-full pb-20">
            <main className="w-full h-full">
              <div className="container md:space-y-20 space-y-16">
                <Outlet />
              </div>
            </main>
          </div>

          <Footer />
          <NotificationContainer />
        </div>
      )}
    </>
  );
}
